import {
    PENDING_LIST,
    PENDING_UPDATED,
    LOADING_ON_PENDING_SUBMIT,
    PENDING_ERROR,
    GET_PENDING_BY_ID,
  } from "actions/types";
  
  const initalState = {
    pendingList: [],
    currentPending: {},
    loading: true,
    listLoading: true,
    error: {},
  };
  
  export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case LOADING_ON_PENDING_SUBMIT:
        return {
          ...state,
          loading: true,
        };
      case PENDING_LIST:
        return {
          ...state,
          listLoading: false,
          pendingList: payload,
        };
      case PENDING_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      case GET_PENDING_BY_ID:
        return {
          ...state,
          currentPending: payload,
          loading: false,
        };
      case PENDING_UPDATED:
        return {
          ...state,
          currentPending: payload,
          loading: false,
        };
      default:
        return state;
    }
  }
  