export const API_URL = process.env.REACT_APP_API_URL;
export const API_BASE_URL = "https://api.virgilproper.com";
export const BASE_SUB_FOLDER = ""; // use "/NAME" for sub-folder
export const ACCESS_URL = "https://www.virgilproper.com/app/grant-access/";
export const DEFAULT_PAGE_SIZE = 10;
export const TABLE_BORDER_COLOR = "#f1f5f1";
export const NO_RECORD = "No Record Found.";
export const USER_TYPE_USER = "user";
export const USER_TYPE_ADMIN = "admin";
export const LOGIN_ROUTE = ["/", "/app", "/app/admin", "/app/admin/login"];

export const DATE_FORMATE = "DD-MM-YYYY";

export const COLUMN_TYPE_DEFAULT = "DEFAULT";
export const COLUMN_TYPE_ADDITIONAL = "ADDITIONAL";

export const COLUMN_ID_DEPRECIATION_PERCENTAGE = 13;
export const COLUMN_ID_RESIDUAL_VALUE = 12;
export const COLUMN_ID_REMAINING_LIFE = 11;
export const COLUMN_ID_CONSUMED_LIFE = 10;

export const COLUMN_NAME_DEPRECIATION_PERCENTAGE = "depreciation percentage";
export const COLUMN_NAME_RESIDUAL_VALUE = "residual value";
export const COLUMN_NAME_REMAINING_LIFE = "remaining life";
export const COLUMN_NAME_CONSUMED_LIFE = "consumed life";

export const DEPRECIATION_TYPE_REDUCING_BALANCE = "REDUCING_BALANCE";
export const REDUCING_BALANCE_NAME = "Reducing Balance";
export const STRAIGHT_LINE_NAME = "Straight Line";

export const COLUMN_DATA_TYPE = [
  {
    id: 1,
    value: "NUMERIC",
  },
  {
    id: 2,
    value: "TEXT",
  },
];

export const ASSET_TYPE = [
  {
    id: 1,
    value: "CONSTRUCTION",
  },
  {
    id: 2,
    value: "DONATION",
  },
  {
    id: 3,
    value: "PURCHASE",
  },
];
