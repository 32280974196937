import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";
import PropTypes from "prop-types";
import * as Constants from "constants/index";
import { resetLink, loginRedirect } from "actions/adminAuth";
import { Button, Form, Input } from "reactstrap";
import Alert from "views/Notifications/Alert";
import loginMain from "assets/otp/otpImg.svg";
import logo from "assets/login/logo.svg";
import arrRight from "assets/login/arrRight.svg";

const Index = ({ resetLink, loginRedirect, errorList, loading, history }) => {
  const [formData, setFormData] = useState({ email: "" });
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState("");
  const { email } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.split(" ").join(""),
    });

    if (e.target.name === "email") {
      validateEmail(e);
    }
  };

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateEmail = (event) => {
    const email = event.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
      setMessage("Your email looks good!");
    } else {
      setIsValid(false);
      setMessage("Please enter a valid email!");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    resetLink(formData, history);
  };

  return (
    <>
      <div className="enterEmail__story">
        <img
          className="enterEmail__img"
          src={loginMain}
          alt="reset email main image"
        />
        <div className="enterEmail__text">
          <p className="enterEmail__text--size">
            Property, Plant & Equipment Management
          </p>
          <img
            className="enterEmail__img--2"
            src={logo}
            alt="enterEmail page logo with name"
          />
        </div>
      </div>
      <div className="login__content">
        <Form className="login__box" onSubmit={(e) => onSubmit(e)}>
          <h2 className="heading-2 mb-s-sm">Enter Email</h2>
          <p className="mb-sm">Enter your registered email</p>
          {/* Alert */}
          <Alert />
          <div className="mb-md mt-sm">
            <label htmlFor="email" className="form__label" aria-hidden="true">
              Email
            </label>
            <Input
              className="form__input login__w32"
              type="text"
              name="email"
              autoFocus
              maxLength="50"
              minLength="5"
              aria-describedby="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => onChange(e)}
              invalid={errorList.email ? true : false}
              required
            />
            <span
              className={`login__err form__label--${
                isValid ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {message}
            </span>
          </div>
          {/* {loading ? (
            <Button className="btn btn-svg login__btn" disabled>
              Loading....
              <img src={arrRight} />{" "}
            </Button>
          ) : ( */}
          <Button
            className="btn btn-svg login__btn"
            type="submit"
            disabled={isValid === true ? false : true}
          >
            Send Email
            <img src={arrRight} />{" "}
          </Button>

          <p className="mt-md">
            <Link
              to={`/app/admin/login`}
              className="login__link"
              onClick={(e) => loginRedirect(history)}
            >
              Already have an account ? Login here
            </Link>
          </p>
        </Form>
      </div>
    </>
  );
};

Index.proTypes = {
  resetLink: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loginRedirect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  resetLink,
  loginRedirect,
})(Index);
