import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getPendingUserData } from "actions/getPendingUserProfileData";
import "reactjs-popup/dist/index.css";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";
import {
  Btn__Loader,
  Screen__Loader,
  Grid__Loader,
} from "../../../ReusableComponents/Loader/index";

const Index = ({ getPendingUserData, match, history }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const pending_user_id = queryParams.get("id");

  const [isGridLoader, setIsGridLoader] = useState(false);
  const [isBtnRejectLoader, setIsBtnRejectLoader] = useState(false);
  const [isBtnApproveLoader, setIsBtnApproveLoader] = useState(false);
  const [isScreenLoader, setIsScreenLoader] = useState(false);

  const selfApiData = JSON.parse(localStorage.getItem("dataSelfApi"));

  useEffect(() => {
    setIsGridLoader(true);

    getPendingUserData(pending_user_id).then((res) => {
      if (res === undefined) {
        setIsGridLoader(false);
      } else {
        setIsGridLoader(false);
      }
    });
  }, [pending_user_id]);

  //cancel form submit
  const onClickHandel = (e) => {
    e.preventDefault();
    // cancelSave(history);
  };

  return (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings listings__loaderPosition">
        {isGridLoader == false && isScreenLoader == false ? (
          <>
            {" "}
            <div className="listing__breadcrum">
              <span className="font-14 op-2 cap">
                Home/Dashboard/
                <span className="c__blue">Admin Profile</span>
              </span>
            </div>
            <div className="listing info__container">
              <div className="companyProfile__header">
                <h3 className="heading-3 mb-md">Admin Profile</h3>
              </div>
              <div className="companyProfile__labels">
                <div className="companyProfile__label">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Name
                  </div>
                  <div className="companyProfile__label-data cap">
                    {selfApiData.name ? selfApiData.name : ""}
                  </div>
                </div>
                <div className="companyProfile__label">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Email
                  </div>
                  <div className="companyProfile__label-data">
                    {selfApiData.email ? selfApiData.email : ""}
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Created On
                    </div>
                    <div className="companyProfile__label-data ">
                      {selfApiData.created_on ? selfApiData.created_on : ""}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="amdin__btnPosition">
            <Link
              className="btn popup__btn btn__blue cap"
              to={`/app/admin/profile-edit`}
            >
              Edit Profile
            </Link>
          </div> */}
              <div className="amdin__btnPosition">
                <Link
                  className="btn popup__btn btn__blue cap"
                  to={`/app/admin/profile-edit`}
                >
                  Edit Profile
                </Link>
              </div>
            </div>
          </>
        ) : (
          <Grid__Loader />
        )}
      </div>
    </>
  );
};

Index.proTypes = {
  // get Classes List data
  getPendingUserData: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentPendingUserProfile: state.pendingProfile.currentPendingUserProfile,

  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPendingUserData,
})(Index);
