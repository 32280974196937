import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Index = ({ history }) => {
  return (
    <div className="sidenavs">
      <div className="sidenav">
        <Link
          to={{
            pathname: "/app/admin/dashboard",
          }}
          replace
          className={`sidenav__li ${
            `/app/admin/dashboard` === window.location.pathname
              ? "sidenav__active"
              : ""
          }`}
        >
          <svg
            className="sidenav__svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14.2222 1.77778V3.55556H10.6667V1.77778H14.2222ZM5.33333 1.77778V7.11111H1.77778V1.77778H5.33333ZM14.2222 8.88889V14.2222H10.6667V8.88889H14.2222ZM5.33333 12.4444V14.2222H1.77778V12.4444H5.33333ZM16 0H8.88889V5.33333H16V0ZM7.11111 0H0V8.88889H7.11111V0ZM16 7.11111H8.88889V16H16V7.11111ZM7.11111 10.6667H0V16H7.11111V10.6667Z" />
          </svg>
          Dashboard
        </Link>

        <Link
          to={{
            pathname: "/app/admin/request-pending-list",
          }}
          replace
          className={`sidenav__li ${
            `/app/admin/request-pending-list` === window.location.pathname
              ? "sidenav__active"
              : ""
          }`}
        >
          <svg
            className="sidenav__svg"
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 20.4C1.1 20.4 0.75 20.25 0.45 19.95C0.15 19.65 0 19.3 0 18.9V3.9C0 3.5 0.15 3.15 0.45 2.85C0.75 2.55 1.1 2.4 1.5 2.4H6.675C6.64167 1.78333 6.85833 1.22917 7.325 0.7375C7.79167 0.245833 8.35 0 9 0C9.65 0 10.2083 0.245833 10.675 0.7375C11.1417 1.22917 11.3583 1.78333 11.325 2.4H16.5C16.9 2.4 17.25 2.55 17.55 2.85C17.85 3.15 18 3.5 18 3.9V18.9C18 19.3 17.85 19.65 17.55 19.95C17.25 20.25 16.9 20.4 16.5 20.4H1.5ZM9 2.85C9.21667 2.85 9.39583 2.77917 9.5375 2.6375C9.67917 2.49583 9.75 2.31667 9.75 2.1C9.75 1.88333 9.67917 1.70417 9.5375 1.5625C9.39583 1.42083 9.21667 1.35 9 1.35C8.78333 1.35 8.60417 1.42083 8.4625 1.5625C8.32083 1.70417 8.25 1.88333 8.25 2.1C8.25 2.31667 8.32083 2.49583 8.4625 2.6375C8.60417 2.77917 8.78333 2.85 9 2.85ZM1.5 17.975C2.5 17.0417 3.63333 16.2875 4.9 15.7125C6.16667 15.1375 7.53333 14.85 9 14.85C10.4667 14.85 11.8333 15.1375 13.1 15.7125C14.3667 16.2875 15.5 17.0417 16.5 17.975V3.9H1.5V17.975ZM9.05 12.875C10.0167 12.875 10.8333 12.5417 11.5 11.875C12.1667 11.2083 12.5 10.3917 12.5 9.425C12.5 8.45833 12.1667 7.64167 11.5 6.975C10.8333 6.30833 10.0167 5.975 9.05 5.975C8.08333 5.975 7.26667 6.30833 6.6 6.975C5.93333 7.64167 5.6 8.45833 5.6 9.425C5.6 10.3917 5.93333 11.2083 6.6 11.875C7.26667 12.5417 8.08333 12.875 9.05 12.875ZM2.875 18.9H15.125C15.125 18.85 15.125 18.8125 15.125 18.7875C15.125 18.7625 15.125 18.725 15.125 18.675C14.225 17.9083 13.2583 17.3292 12.225 16.9375C11.1917 16.5458 10.1167 16.35 9 16.35C7.88333 16.35 6.80833 16.5458 5.775 16.9375C4.74167 17.3292 3.775 17.9083 2.875 18.675C2.875 18.725 2.875 18.7625 2.875 18.7875C2.875 18.8125 2.875 18.85 2.875 18.9ZM9.05 11.375C8.51667 11.375 8.05833 11.1833 7.675 10.8C7.29167 10.4167 7.1 9.95833 7.1 9.425C7.1 8.875 7.29167 8.4125 7.675 8.0375C8.05833 7.6625 8.51667 7.475 9.05 7.475C9.6 7.475 10.0625 7.6625 10.4375 8.0375C10.8125 8.4125 11 8.875 11 9.425C11 9.95833 10.8125 10.4167 10.4375 10.8C10.0625 11.1833 9.6 11.375 9.05 11.375Z" />
          </svg>
          Pending Requests
        </Link>

        <Link
          to={{
            pathname: "/app/admin/active-user-list",
          }}
          replace
          className={`sidenav__li ${
            `/app/admin/active-user-list` === window.location.pathname
              ? "sidenav__active"
              : ""
          }`}
        >
          <svg
            className="sidenav__svg"
            width="16"
            height="16"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
            <path d="M9 8.4375C7.7625 8.4375 6.75 8.04375 5.9625 7.25625C5.175 6.46875 4.78125 5.45625 4.78125 4.21875C4.78125 2.98125 5.175 1.96875 5.9625 1.18125C6.75 0.39375 7.7625 0 9 0C10.2375 0 11.25 0.39375 12.0375 1.18125C12.825 1.96875 13.2188 2.98125 13.2188 4.21875C13.2188 5.45625 12.825 6.46875 12.0375 7.25625C11.25 8.04375 10.2375 8.4375 9 8.4375ZM0 17.4656V14.8219C0 14.1094 0.178125 13.5 0.534375 12.9937C0.890625 12.4875 1.35 12.1031 1.9125 11.8406C3.16875 11.2781 4.37344 10.8562 5.52656 10.575C6.67969 10.2937 7.8375 10.1531 9 10.1531C10.1625 10.1531 11.3156 10.2984 12.4594 10.5891C13.6031 10.8797 14.8031 11.2969 16.0594 11.8406C16.6406 12.1031 17.1094 12.4875 17.4656 12.9937C17.8219 13.5 18 14.1094 18 14.8219V17.4656H0ZM1.6875 15.7781H16.3125V14.8219C16.3125 14.5219 16.2234 14.2359 16.0453 13.9641C15.8672 13.6922 15.6469 13.4906 15.3844 13.3594C14.1844 12.7781 13.0875 12.3797 12.0938 12.1641C11.1 11.9484 10.0688 11.8406 9 11.8406C7.93125 11.8406 6.89063 11.9484 5.87813 12.1641C4.86563 12.3797 3.76875 12.7781 2.5875 13.3594C2.325 13.4906 2.10938 13.6922 1.94062 13.9641C1.77187 14.2359 1.6875 14.5219 1.6875 14.8219V15.7781ZM9 6.75C9.73125 6.75 10.3359 6.51094 10.8141 6.03281C11.2922 5.55469 11.5312 4.95 11.5312 4.21875C11.5312 3.4875 11.2922 2.88281 10.8141 2.40469C10.3359 1.92656 9.73125 1.6875 9 1.6875C8.26875 1.6875 7.66406 1.92656 7.18594 2.40469C6.70781 2.88281 6.46875 3.4875 6.46875 4.21875C6.46875 4.95 6.70781 5.55469 7.18594 6.03281C7.66406 6.51094 8.26875 6.75 9 6.75Z" />
          </svg>
          Active Users
        </Link>

        <Link
          to={{
            pathname: "/app/admin/inactive-user-list",
          }}
          replace
          className={`sidenav__li ${
            `/app/admin/inactive-user-list` === window.location.pathname
              ? "sidenav__active"
              : ""
          }`}
        >
          <svg
            className="sidenav__svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.0264 18L14.7849 15.7585H2.24151V13.6302C2.24151 13.0566 2.38491 12.566 2.6717 12.1585C2.95849 11.7509 3.3283 11.4415 3.78113 11.2302C4.68679 10.8226 5.55849 10.5057 6.39623 10.2792C7.23396 10.0528 8.07547 9.92453 8.92076 9.89434L0 0.973585L0.973585 0L18 17.0264L17.0264 18ZM3.6 14.4H13.4264L10.2792 11.2528C10.1585 11.2377 10.0302 11.2302 9.89434 11.2302C9.75849 11.2302 9.62264 11.2302 9.48679 11.2302C8.62642 11.2302 7.78868 11.317 6.97359 11.4906C6.15849 11.6642 5.27547 11.9849 4.32453 12.4528C4.11321 12.5585 3.93962 12.7208 3.80377 12.9396C3.66792 13.1585 3.6 13.3887 3.6 13.6302V14.4ZM15.1698 11.2302C15.6377 11.4415 16.0151 11.7509 16.3019 12.1585C16.5887 12.566 16.7321 13.0566 16.7321 13.6302V13.8113L13.4943 10.5736C13.766 10.6642 14.0415 10.766 14.3208 10.8792C14.6 10.9925 14.883 11.1094 15.1698 11.2302ZM11.0717 8.15094L9.98491 7.06415C10.4377 6.95849 10.8075 6.72453 11.0943 6.36226C11.3811 6 11.5245 5.57736 11.5245 5.09434C11.5245 4.52076 11.3283 4.03774 10.9359 3.64528C10.5434 3.25283 10.0604 3.0566 9.48679 3.0566C9.00377 3.0566 8.58113 3.2 8.21887 3.48679C7.8566 3.77358 7.62264 4.1434 7.51698 4.59623L6.43019 3.50943C6.71698 2.93585 7.13585 2.49057 7.68679 2.17358C8.23774 1.8566 8.83774 1.69811 9.48679 1.69811C10.4377 1.69811 11.2415 2.02642 11.8981 2.68302C12.5547 3.33962 12.883 4.1434 12.883 5.09434C12.883 5.7434 12.7245 6.3434 12.4075 6.89434C12.0906 7.44528 11.6453 7.86415 11.0717 8.15094ZM13.4264 14.4H3.6C3.6 14.4 3.66792 14.4 3.80377 14.4C3.93962 14.4 4.11321 14.4 4.32453 14.4C4.80755 14.4 5.28679 14.4 5.76226 14.4C6.23774 14.4 6.80755 14.4 7.4717 14.4C8.13585 14.4 8.93962 14.4 9.88302 14.4C10.8264 14.4 12.0075 14.4 13.4264 14.4Z" />
          </svg>
          Inactive Users
        </Link>

        <Link
          to={{
            pathname: "/app/admin/unverified-user-list",
          }}
          replace
          className={`sidenav__li ${
            `/app/admin/unverified-user-list` === window.location.pathname
              ? "sidenav__active"
              : ""
          }`}
        >
          <svg
            className="sidenav__svg"
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 8.75C4.66 8.75 0 9.92 0 12.25V14H14V12.25C14 9.92 9.34 8.75 7 8.75ZM2.34 12C3.18 11.42 5.21 10.75 7 10.75C8.79 10.75 10.82 11.42 11.66 12H2.34ZM7 7C8.93 7 10.5 5.43 10.5 3.5C10.5 1.57 8.93 0 7 0C5.07 0 3.5 1.57 3.5 3.5C3.5 5.43 5.07 7 7 7ZM7 2C7.83 2 8.5 2.67 8.5 3.5C8.5 4.33 7.83 5 7 5C6.17 5 5.5 4.33 5.5 3.5C5.5 2.67 6.17 2 7 2ZM14.04 8.81C15.2 9.65 16 10.77 16 12.25V14H20V12.25C20 10.23 16.5 9.08 14.04 8.81ZM13 7C14.93 7 16.5 5.43 16.5 3.5C16.5 1.57 14.93 0 13 0C12.46 0 11.96 0.13 11.5 0.35C12.13 1.24 12.5 2.33 12.5 3.5C12.5 4.67 12.13 5.76 11.5 6.65C11.96 6.87 12.46 7 13 7Z" />
          </svg>
          Unverified Users
        </Link>
      </div>
    </div>
  );
};

Index.proTypes = {
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Index);
