import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selfAuth } from "actions/adminAuth";

const PublicRoute = ({
  selfAuth,
  match,
  history,
  component: Component,
  auth: { isAuthenticated, loading, token, selfLoaded },
  restricted,
  ...rest
}) => {

  useEffect(() => {
    selfAuth();
  }, [selfAuth]);


  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && !loading && !restricted) {
          return <Component {...props} />;
        } else {
          if (token !== null) {
            return <Redirect to="/app/admin/dashboard" />;
          }
          return <Component {...props} />;
        }
      }}
    />
  );
};

PublicRoute.propTypes = {
  // selfAuth
  selfAuth: PropTypes.func.isRequired,
  //
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  selfAuth,
})(PublicRoute);
