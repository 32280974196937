import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./index.scss";
import { getPendingUserData } from "actions/getPendingUserProfileData";
import { updateAdmin } from "actions/admin";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";

const Index = ({ updateAdmin, getPendingUserData, match, history }) => {
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    email: "",
  });
  const { name, password, email } = formData;

  const queryParams = new URLSearchParams(window.location.search);
  const pending_user_id = queryParams.get("id");

  const selfApiData = JSON.parse(localStorage.getItem("dataSelfApi"));
  const admin_id = selfApiData.id;

  useEffect(() => {
    if (selfApiData) {
      setFormData({
        name: selfApiData.name,
        email: selfApiData.email,
        password: selfApiData.password,
      });
    }
  }, []);
  useEffect(() => {
    getPendingUserData(pending_user_id);
  }, [pending_user_id]);

  //handle onChange event
  const onChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  //submit form
  const onSubmit = (e) => {
    e.preventDefault();
    updateAdmin(admin_id, name, password, history);
  };
  //cancel form submit
  const onClickHandel = (e) => {
    e.preventDefault();
    // cancelSave(history);
  };

  return (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings">
        <div className="listing__breadcrum">
          <span className="font-14 op-2 cap">
            Home/Dashboard/
            <span className="c__blue">Admin Profile Edit</span>
          </span>
        </div>

        <div className="listing info__container">
          <div className="companyProfile__header">
            <h3 className="heading-3">Admin Profile Edit</h3>
          </div>
          <Form onSubmit={(e) => onSubmit(e)}>
            <div className="companyProfile__labels">
              <div className="companyProfile__label">
                <div className="companyProfile__label-data upper">
                  <label
                    htmlFor="name"
                    className="form__label c__blue cap  mb-s-sm"
                    aria-hidden="true"
                  >
                    Name
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    name="name"
                    minLength="3"
                    maxLength="64"
                    aria-describedby="Name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
              </div>
              {/* email */}
              <div className="physical__box">
                <div className="companyProfile__label">
                  <label
                    htmlFor="email"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Email
                  </label>
                  <Input
                    className="form__input not-allowed"
                    type="text"
                    name="email"
                    disabled={true}
                    aria-describedby="Email"
                    placeholder="Email"
                    value={email}
                  />
                </div>
              </div>

              {/* phone */}
              <div className="physical__box">
                <div className="companyProfile__label">
                  <label
                    htmlFor="password"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Password
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    name="password"
                    minLength="3"
                    maxLength="64"
                    aria-describedby="Password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    // required
                  />
                </div>
              </div>
            </div>

            <div className="popup__btns editProfile__btns mt-md">
              <Link
                to="/app/admin/profile"
                className="btn btn__border"
                // onClick={(e) => accountInfoCompanyRedirect(history)}
              >
                Cancel
              </Link>
              <Button className="btn popup__btn btn__blue cap" type="submit">
                update
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

Index.proTypes = {
  // updateAdmin
  updateAdmin: PropTypes.func.isRequired,
  // get Classes List data
  getPendingUserData: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentPendingUserProfile: state.pendingProfile.currentPendingUserProfile,
  currentAdmin: state.admin.currentAdmin,
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPendingUserData,
  updateAdmin,
})(Index);
