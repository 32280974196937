import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./index.scss";
import PropTypes from "prop-types";
import {
  getPendingUserList,
  approveUser,
  rejectPendingUser,
} from "actions/pending";
import { Input } from "reactstrap";
import DataTable from "react-data-table-component";
import "reactjs-popup/dist/index.css";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";
import eye from "assets/land/eye.svg";
import activeTic from "assets/land/active.svg";
import deleteCross from "assets/superAdmin/PendingReques/deleteCross.svg";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: "1.5rem",
      textTransform: "capitalize",
      paddingLeft: ".5rem", // override the cell padding for head cells
      paddingRight: ".5rem",
    },
  },
  rows: {
    style: {
      minHeight: "3rem", // override the row height
    },
  },
  cells: {
    style: {
      paddingLeft: ".5rem", // override the cell padding for data cells
      paddingRight: ".5rem",
    },
  },
};

const Index = ({
  approveUser,
  rejectPendingUser,
  getPendingUserList,
  pendingList,
  match,
  history,
}) => {
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "4rem",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Date Applied",
      selector: (row) => row.created_on,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.action,
      center: true,
      cell: (row) => {
        return (
          <div className="listing__actions">
            <Link to={`/app/admin/list-info?name=${row.name}&id=${row.id}`}>
              <img className="cursor" src={eye} alt="edit status" />
            </Link>
            {row.status !== 1 ? (
              <>
                <img
                  className="cursor"
                  src={activeTic}
                  alt="right check status"
                  onClick={() => {
                    print(row);
                  }}
                />
              </>
            ) : (
              ""
            )}
            <img
              className="cursor"
              src={deleteCross}
              alt="deleteCross status"
              onClick={() => {
                delete_user(row);
              }}
            />
          </div>
        );
      },
    },
  ];

  const print = (row) => {
    const active_status = 1;
    approveUser(row.id, row.name, row.email, active_status, history);
  };
  const delete_user = (row) => {
    const user_id = row.id;
    const status_reject = 3;
    rejectPendingUser(user_id, status_reject, history);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const name = queryParams.get("name");
  const class_id = queryParams.get("id");

  // useEffect(() => {
  //   getPendingUserList();
  // }, []);

  const [pending, setPending] = useState(true);
  useEffect(() => {
    getPendingUserList().then((res) => {
      if (res === undefined) {
        setPending(false);
      } else {
        setPending(false);
      }
    });
  }, [getPendingUserList]);
  //////////////////SEARCHING/FILTER///////////////////

  const [searchs, setNewSearch] = useState("");

  const handleSearchChange = (e) => {
    setNewSearch(e.target.value);
  };

  const filtered = !searchs
    ? pendingList
    : pendingList.filter(
        (el) =>
          el.name.toLowerCase().includes(searchs.toLowerCase()) ||
          el.email.toLowerCase().includes(searchs.toLowerCase()) ||
          el.phone.toLowerCase().includes(searchs.toLowerCase()) ||
          el.created_on.toLowerCase().includes(searchs.toLowerCase())
      );
  //////////////////SEARCHING/FILTER///////////////////

  return (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings">
        <div className="listing__breadcrum">
          <span className="font-14 ">
            <Link className="op-2" to="/app/admin/dashboard">
              Home/ Dashboard/{" "}
            </Link>
            <span className="c__blue">Pending Requests</span>
          </span>
        </div>
        <div className="listing">
          <div className="listing__header mb-md">
            <h3 className="heading-3 cap">{name}Pending Requests</h3>
            <div className="listing__right">
              <Input
                className="form__search"
                type="search"
                name="search"
                placeholder="Search..."
                value={searchs}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <DataTable
            columns={columns}
            data={filtered}
            // data={pendingData}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
    </>
  );
};

Index.proTypes = {
  getPendingUserList: PropTypes.object.isRequired,
  pendingList: PropTypes.array.isRequired,
  approveUser: PropTypes.object.isRequired,
  rejectPendingUser: PropTypes.object.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  pendingList: state.pending.pendingList,
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPendingUserList,
  approveUser,
  rejectPendingUser,
})(Index);
