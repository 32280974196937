import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import {
  PENDING_UPDATED,
  PENDING_DELETE,
  LOADING_ON_PENDING_SUBMIT,
  GET_PENDING_BY_ID,
  PENDING_ERROR,
  PENDING_LIST,
  REMOVE_ERRORS,
  REMOVE_ALERT,
} from "./types.js";
import * as Constants from "constants/index";

// approve User
export const approveUser =
  (id, name, email, status, history) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    };
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnSubmit());

    const body = JSON.stringify({
      name,
      email,
      status,
    });

    try {
      const res = await axios.put(
        `${Constants.API_BASE_URL}/admin/users/${id}`,
        body,
        config
      );

      if (res.data.status === 200) {
        dispatch({
          type: PENDING_UPDATED,
          payload: res.data,
        });
        // reload
        window.location.reload();
        // success
        dispatch(setAlert(`${res.data.message}`, "success"));
      }
    } catch (err) {
      dispatch({ type: PENDING_ERROR });
      dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
      const errors = err.response.data.message;
      if (errors.length > 0) {
        errors.forEach((error) => {
          dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
        });
      }
    }
  };

// approve User
export const approveUserPageInfo =
  (id, name, email, status, history) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    };
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnSubmit());

    const body = JSON.stringify({
      name,
      email,
      status,
    });

    try {
      const res = await axios.put(
        `${Constants.API_BASE_URL}/admin/users/${id}`,
        body,
        config
      );

      if (res.data.status === 200) {
        dispatch({
          type: PENDING_UPDATED,
          payload: res.data,
        });
        history.push("/app/admin/request-pending-list");
        // success
        dispatch(setAlert(`${res.data.message}`, "success"));
      }
    } catch (err) {
      dispatch({ type: PENDING_ERROR });
      dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
      const errors = err.response.data.message;
      if (errors.length > 0) {
        errors.forEach((error) => {
          dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
        });
      }
    }
  };

// Reject Pending user
export const rejectPendingUser = (id, status, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };

  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnSubmit());

  const body = JSON.stringify({ status });

  try {
    const res = await axios.put(
      `${Constants.API_BASE_URL}/admin/users/${id}`,
      body,
      config
    );

    if (res.data.status === 200) {
      dispatch({
        type: PENDING_DELETE,
        payload: null,
      });
      history.push("/app/admin/request-pending-list");
      // reload
      window.location.reload();
      // success
      dispatch(setAlert(`${res.data.message}`, "success"));
      return null;
    }
  } catch (err) {
    dispatch({ type: PENDING_ERROR });
    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
    return undefined;
  }
};

// Active User LIST
export const getActiveUserList = () => async (dispatch) => {
  dispatch({
    type: PENDING_LIST,
    payload: [],
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };

  try {
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/users?status=1`,
      config
    );
    dispatch({
      type: PENDING_LIST,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: PENDING_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status },
    });

    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
    return undefined;
  }
};

// Inactive User LIST
export const getInactiveUserList = () => async (dispatch) => {
  dispatch({
    type: PENDING_LIST,
    payload: [],
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };

  try {
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/users?status=3`,
      config
    );
    dispatch({
      type: PENDING_LIST,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: PENDING_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status },
    });

    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
    return undefined;
  }
};
// Pending User LIST
export const getPendingUserList = () => async (dispatch) => {
  dispatch({
    type: PENDING_LIST,
    payload: [],
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };

  try {
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/users?status=2`,
      config
    );
    dispatch({
      type: PENDING_LIST,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: PENDING_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status },
    });

    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
    return undefined;
  }
};

// Data BY ID
export const getPendingById = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    };
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/users/${id}`,
      config
    );
    dispatch({
      type: GET_PENDING_BY_ID,
      payload: res.data.data,
    });
    // console.log('res',res.data.data)
    // dispatch(setAlert(`${res.data.message}`,"success"));

    return res.data.data;
  } catch (err) {
    dispatch({
      type: PENDING_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status },
    });

    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
    return undefined;
  }
};

// Dispatch Loading
export const loadingOnSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_PENDING_SUBMIT });
};

// Redirect to xxx screen
export const assetRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};

// Cancel Submit  xxxx
export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};
