import React,{useEffect} from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { selfAuth } from "actions/adminAuth";
import store from "store";
import * as Constants from "constants/index";
// ALL COMPONENTS
import HomePage from "views/Components";
// NOT FOUND PAGE
import NotFoundPage from "views/NotFound";
// ADMIN
import AdminLogin from "views/AdminAuth/Login";
import AdminRegister from "views/AdminAuth/Register/index";
import AdminOTP from "views/AdminAuth/OTP/index";
import AdminEnterRegisterdEmail from 'views/AdminAuth/ForgotFlow/EnterEmail/index';
import AdminResetPassword from 'views/AdminAuth/ForgotFlow/ResetPassword/index';

const Routes = () => {
  useEffect(() => {

    if(localStorage.token){
    store.dispatch(selfAuth());
}
  }, []);
  

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Switch>
      {/* USER */}
      {Constants.LOGIN_ROUTE.map((route) => (
        <PublicRoute
          exact
          key={route}
          path={route}
          restricted={true}
          component={AdminLogin}
        />
      ))}
      {/* ADMIN */}
      <PublicRoute exact path={`/app/admin/register`} restricted={true} component={AdminRegister} />
      <PublicRoute exact path={`/app/admin/otp`}   restricted={true} component={AdminOTP} />
      <PublicRoute exact path={`/app/admin/enter-registerd-email`} restricted={true} component={AdminEnterRegisterdEmail} />
      <PublicRoute exact path={`/app/admin/reset-password`} restricted={true} component={AdminResetPassword} />
      
      <PrivateRoute exact path={`/app/*`} name="Home" component={HomePage} />

      {/* NOT FOUND */}
      {/* <Route exact path="/app/page-not-found" component={NotFoundPage} /> */}

    </Switch>
  );
};

export default Routes;
