import axios from "axios";
import { setAlert, removeAlert } from "./alert";
import { setErrorsList } from "./errors";
import {
  DASHBOARD_LIST,
  LOADING_ON_DASHBOARD_SUBMIT,
  DASHBOARD_ERROR,
  REMOVE_ERRORS,
  REMOVE_ALERT,
  DASHBOARD_OBJECT,
} from "./types.js";
import { selfAuth } from "./adminAuth";
import * as Constants from "constants/index";

// Update depreciation_type
export const getDashboard = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };

  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnSubmit());

  try {
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/dashboard/`,
      config
    );

    if (res.data.status === 200) {
      dispatch({
        type: DASHBOARD_LIST,
        payload: res.data.data,
      });
      dispatch({
        type: DASHBOARD_OBJECT,
        payload: res.data.data,
      });
      return res.data.data;

      // call selfLoad api
      selfAuth();
    }
  } catch (err) {
    dispatch({ type: DASHBOARD_ERROR });
    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
  }
};

// Dispatch Loading
export const loadingOnSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_DASHBOARD_SUBMIT });
};

// Redirect to dashboard screen
export const dashboardRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/app/dashboard");
};
