// NOT FOUND
import NotFoundPage from "views/NotFound";
// super admin
import AdminDashboard from "views/Components/SuperAdmin/Dashboard/index";
import RequestPendingList from "views/Components/SuperAdmin/PendingRequest/PendingList/Index";
import ListInfo from "views/Components/SuperAdmin/PendingInfo/index";
import AdminProfileInfo from "views/Components/SuperAdmin/ProfileInfo/index";
import InactiveProfile from "views/Components/SuperAdmin/InactiveProfile/index";
import AdminProfile from "views/Components/SuperAdmin/AdminProfile/index";
import AdminProfileEdit from "views/Components/SuperAdmin/AdminProfileEdit/index";
import ActiveUserEdit from "views/Components/SuperAdmin/UserEdit/index";
import ActiveUserList from "views/Components/SuperAdmin/ActiveUser/UserList/index";
import InactiveUserList from "views/Components/SuperAdmin/InactiveUser/UserList/index";
import UnverifiedList from "views/Components/SuperAdmin/UnverifiedList/index";

const ComponentRoutes = [
  // SUPER ADMIN
  {
    path: `/app/admin/dashboard`,
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: `/app/admin/request-pending-list`,
    name: "RequestPendingList",
    component: RequestPendingList,
  },
  {
    path: `/app/admin/list-info`,
    name: "ListInfo",
    component: ListInfo,
  },
  {
    path: `/app/admin/profile`,
    name: "AdminProfile",
    component: AdminProfile,
  },
  {
    path: `/app/admin/profile-edit`,
    name: "AdminProfileEdit",
    component: AdminProfileEdit,
  },
  {
    path: `/app/admin/active-user-profile`,
    name: "AdminProfileInfo",
    component: AdminProfileInfo,
  },
  {
    path: `/app/admin/inactive-user-profile`,
    name: "InactiveProfile",
    component: InactiveProfile,
  },
  {
    path: `/app/admin/active-user-list`,
    name: "ActiveUserList",
    component: ActiveUserList,
  },
  {
    path: `/app/admin/active-user-edit`,
    name: "ActiveUserEdit",
    component: ActiveUserEdit,
  },
  {
    path: `/app/admin/unverified-user-list`,
    name: "UnverifiedList",
    component: UnverifiedList,
  },
  {
    path: `/app/admin/inactive-user-list`,
    name: "InactiveUserList",
    component: InactiveUserList,
  },
  // NOT FOUND
  {
    path: "/app/page-not-found",
    name: "404",
    component: NotFoundPage,
  },
];

export default ComponentRoutes;
