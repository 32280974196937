import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import {
  UNVERIFIED_UPDATED,
  UNVERIFIED_DELETE,
  LOADING_ON_UNVERIFIED_SUBMIT,
  GET_UNVERIFIED_BY_ID,
  UNVERIFIED_ERROR,
  UNVERIFIED_LIST,
  REMOVE_ERRORS,
  REMOVE_ALERT,
} from "./types.js";
import * as Constants from "constants/index";

// Reject Unverified user
export const rejecUnverifiedtUser = (id, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };

  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnSubmit());

  try {
    const res = await axios.delete(
      `${Constants.API_BASE_URL}/admin/unverified-users/${id}`,
      config
    );

    if (res.data.status === 200) {
      dispatch({
        type: UNVERIFIED_DELETE,
        payload: null,
      });
      // reload
      window.location.reload();
      // success
      dispatch(setAlert(`${res.data.message}`, "success"));
    }
  } catch (err) {
    dispatch({ type: UNVERIFIED_ERROR });
    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
  }
};

// ALL LIST
export const getUnverifiedList = () => async (dispatch) => {
  dispatch({
    type: UNVERIFIED_LIST,
    payload: [],
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };

  try {
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/unverified-users/`,
      config
    );
    dispatch({
      type: UNVERIFIED_LIST,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: UNVERIFIED_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status },
    });

    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
  }
};

// Data BY ID
export const getUnverifiedById = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    };
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/users/${id}`,
      config
    );
    dispatch({
      type: GET_UNVERIFIED_BY_ID,
      payload: res.data.data,
    });
    // console.log('res',res.data.data)
    // dispatch(setAlert(`${res.data.message}`,"success"));

    return res.data.data;
  } catch (err) {
    dispatch({
      type: UNVERIFIED_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status },
    });

    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
    return undefined;
  }
};

// Dispatch Loading
export const loadingOnSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_UNVERIFIED_SUBMIT });
};

// Cancel Submit  xxxx
export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};
