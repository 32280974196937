import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { getPendingUserData } from "actions/getPendingUserProfileData";
import Spinner from "views/Spinner";
import "reactjs-popup/dist/index.css";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";
import eye from "assets/land/eye.svg";
import activeTic from "assets/land/active.svg";
import deleteCross from "assets/superAdmin/PendingReques/deleteCross.svg";
import {
  Btn__Loader,
  Screen__Loader,
  Grid__Loader,
} from "../../../ReusableComponents/Loader/index";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: "1.5rem",
      textTransform: "capitalize",
      paddingLeft: ".5rem", // override the cell padding for head cells
      paddingRight: ".5rem",
    },
  },
  rows: {
    style: {
      minHeight: "4rem", // override the row height
    },
  },
  cells: {
    style: {
      paddingLeft: ".5rem", // override the cell padding for data cells
      paddingRight: ".5rem",
    },
  },
};
const columns = [
  {
    name: "Sr",
    selector: (row, index) => index + 1,
    sortable: true,
    maxWidth: "4rem",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    grow: 2,
  },
  {
    name: "Date Added",
    selector: (row) => row.dateAdded,
    sortable: true,
  },
  {
    name: "Access Right",
    selector: (row) => row.accessRight,
    sortable: true,
    cell: (row) => {
      return (
        <div className=" ">
          <span
            className={`profileInfo__access ${
              row.accessRight === "Read Only"
                ? "profileInfo__access__readOnly"
                : "profileInfo__access__fullAccess"
            } `}
          >
            {row.accessRight}
          </span>
        </div>
      );
    },
  },
  {
    name: "Actions",
    sortable: true,
    center: true,
    selector: (row) => row.action,
    cell: () => {
      return (
        <div className="listing__actions">
          <img className="cursor" src={eye} alt="edit status" />
          <img className="cursor" src={activeTic} alt="right check status" />
          <img className="cursor" src={deleteCross} alt="deleteCross status" />
        </div>
      );
    },
  },
];

const data = [
  {
    sr: 1,
    name: "Abckd",
    email: "beetlejuice@gmail.com",
    dateAdded: "4 Yrs",
    accessRight: "Full Access",
    actions: "Actions",
  },
  {
    sr: 2,
    name: "Abckd",
    email: "beetlejuice@gmail.com",
    dateAdded: "4 Yrs",
    accessRight: "Read Only",
    actions: "Actions",
  },
];

const Index = ({
  getPendingUserData,
  currentPendingUserProfile,
  match,
  history,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const pending_user_name = queryParams.get("name");
  const pending_user_id = queryParams.get("id");

  const [isGridLoader, setIsGridLoader] = useState(false);
  const [isBtnRejectLoader, setIsBtnRejectLoader] = useState(false);
  const [isBtnApproveLoader, setIsBtnApproveLoader] = useState(false);
  const [isScreenLoader, setIsScreenLoader] = useState(false);

  useEffect(() => {
    setIsGridLoader(true);

    getPendingUserData(pending_user_id).then((res) => {
      if (res === undefined) {
        setIsGridLoader(false);
      } else {
        setIsGridLoader(false);
      }
    });
  }, [pending_user_id]);

  //cancel form submit
  const onClickHandel = (e) => {
    e.preventDefault();
    // cancelSave(history);
  };

  return currentPendingUserProfile ? (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings listings__loaderPosition">
        {isGridLoader == false && isScreenLoader == false ? (
          <>
            <div className="listing__breadcrum">
              <span className="font-14 ">
                <Link className="op-2" to="/app/admin/dashboard">
                  Home/ Dashboard/{" "}
                </Link>
                <span className="c__blue">Profile Info</span>
              </span>
            </div>

            <div className="listing info__container">
              <div className="companyProfile__header">
                <h3 className="heading-3 mb-md">Profile Info</h3>
              </div>
              <div className="companyProfile__labels">
                <div className="companyProfile__label">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Name
                  </div>
                  <div className="companyProfile__label-data cap">
                    {currentPendingUserProfile.name
                      ? currentPendingUserProfile.name
                      : ""}
                  </div>
                </div>
                <div className="companyProfile__label">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Email
                  </div>
                  <div className="companyProfile__label-data">
                    {currentPendingUserProfile.email
                      ? currentPendingUserProfile.email
                      : ""}
                  </div>
                </div>

                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Phone Number
                    </div>
                    <div className="companyProfile__label-data ">
                      {currentPendingUserProfile.phone
                        ? currentPendingUserProfile.phone
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Date Applied
                    </div>
                    <div className="companyProfile__label-data ">
                      {currentPendingUserProfile.created_on
                        ? currentPendingUserProfile.created_on
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Framework
                    </div>
                    <div className="companyProfile__label-data upper">
                      {currentPendingUserProfile.organization &&
                      currentPendingUserProfile.organization.reporting_framework
                        ? currentPendingUserProfile.organization
                            .reporting_framework
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Depreciation Method
                    </div>
                    <div className="companyProfile__label-data upper">
                      <div className="companyProfile__label-data c__black">
                        {currentPendingUserProfile.organization &&
                        currentPendingUserProfile.organization
                          .depreciation_type === "REDUCING_BALANCE"
                          ? "REDUCING BALANCE"
                          : currentPendingUserProfile.organization &&
                            currentPendingUserProfile.organization
                              .depreciation_type === "STRAIGHT_LINE"
                          ? "STRAIGHT LINE"
                          : ""}
                      </div>
                      {/* {currentPendingUserProfile.organization && currentPendingUserProfile.organization.depreciation_type?currentPendingUserProfile.organization.depreciation_type:''} */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="physical__box mt-md-6 mb-md">
                <div className="companyProfile__label companyProfile__mt">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Classes
                  </div>
                  {/* 1 */}
                  {currentPendingUserProfile.classes &&
                    currentPendingUserProfile.classes.map((el, id) => {
                      return (
                        <>
                          <div className="profileInfo__classes cap">
                            {el.name}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Grid__Loader />
        )}
      </div>
    </>
  ) : (
    <Spinner />
  );
};

Index.proTypes = {
  // get Classes List data
  getPendingUserData: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentPendingUserProfile: state.pendingProfile.currentPendingUserProfile,
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPendingUserData,
})(Index);
