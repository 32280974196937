import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./index.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  getPendingUserData,
  userAccessKeyApi,
} from "actions/getPendingUserProfileData";
import Spinner from "views/Spinner";
import * as Constants from "constants/index";
import "reactjs-popup/dist/index.css";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";
import eye from "assets/land/eye.svg";
import edit from "assets/land/edit.svg";
import {
  Btn__Loader,
  Screen__Loader,
  Grid__Loader,
} from "../../../ReusableComponents/Loader/index";

const Index = ({
  getPendingUserData,
  userAccessKeyApi,
  currentPendingUserProfile,
  match,
  history,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const active_user_id = queryParams.get("id");
  const active_user_name = queryParams.get("name");

  const [isGridLoader, setIsGridLoader] = useState(false);
  const [isBtnRejectLoader, setIsBtnRejectLoader] = useState(false);
  const [isBtnApproveLoader, setIsBtnApproveLoader] = useState(false);
  const [isScreenLoader, setIsScreenLoader] = useState(false);

  useEffect(() => {
    setIsGridLoader(true);

    getPendingUserData(active_user_id).then((res) => {
      if (res === undefined) {
        setIsGridLoader(false);
      } else {
        setIsGridLoader(false);
      }
    });
  }, [active_user_id]);

  //userAccessKey
  const userAccessKey = (e) => {
    e.preventDefault();
    userAccessKeyApi(active_user_id);
  };

  //cancel form submit
  const onClickHandel = (e) => {
    e.preventDefault();
    // cancelSave(history);
  };

  return currentPendingUserProfile ? (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings listings__loaderPosition">
        {isGridLoader == false && isScreenLoader == false ? (
          <>
            <div className="listing__breadcrum">
              <span className="font-14 ">
                <Link className="op-2" to="/app/admin/dashboard">
                  Home/ Dashboard/{" "}
                </Link>
                <span className="c__blue">Profile Info</span>
              </span>
            </div>

            <div className="listing info__container">
              <div className="companyProfile__header">
                <h3 className="heading-3 mb-md">User Profile Info</h3>
                <div className="companyProfile__access">
                  <Link
                    className=""
                    to={`/app/admin/active-user-edit?name=${active_user_name}&id=${active_user_id}`}
                    // onClick={(e) => userAccessKey(e)}
                  >
                    <img src={edit} alt="edit here" />
                    <span className="companyProfile__blue">Edit</span>
                  </Link>
                  <Link className="" onClick={(e) => userAccessKey(e)}>
                    <img className="" src={eye} alt="view user" />
                    <span className="companyProfile__green">View as User</span>
                  </Link>
                </div>
              </div>
              <div className="companyProfile__labels">
                <div className="companyProfile__label">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Name
                  </div>
                  <div className="companyProfile__label-data cap">
                    {currentPendingUserProfile.name
                      ? currentPendingUserProfile.name
                      : ""}
                  </div>
                </div>
                <div className="companyProfile__label">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Email
                  </div>
                  <div className="companyProfile__label-data">
                    {currentPendingUserProfile.email
                      ? currentPendingUserProfile.email
                      : ""}
                  </div>
                </div>

                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Phone Number
                    </div>
                    <div className="companyProfile__label-data ">
                      {currentPendingUserProfile.phone
                        ? currentPendingUserProfile.phone
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Date Applied
                    </div>
                    <div className="companyProfile__label-data ">
                      {currentPendingUserProfile.created_on
                        ? currentPendingUserProfile.created_on
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Framework
                    </div>
                    <div className="companyProfile__label-data upper">
                      {currentPendingUserProfile.organization &&
                      currentPendingUserProfile.organization.reporting_framework
                        ? currentPendingUserProfile.organization
                            .reporting_framework
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Depreciation Method
                    </div>
                    <div className="companyProfile__label-data upper">
                      <div className="companyProfile__label-data c__black cap">
                        {currentPendingUserProfile.organization &&
                        currentPendingUserProfile.organization
                          .depreciation_type === "REDUCING_BALANCE"
                          ? `${Constants.REDUCING_BALANCE_NAME}`
                          : currentPendingUserProfile.organization &&
                            currentPendingUserProfile.organization
                              .depreciation_type === "STRAIGHT_LINE"
                          ? `${Constants.STRAIGHT_LINE_NAME}`
                          : ""}
                      </div>
                      {/* {currentPendingUserProfile.organization && currentPendingUserProfile.organization.depreciation_type?currentPendingUserProfile.organization.depreciation_type:''} */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="physical__box mt-md-6 mb-md">
                <div className="companyProfile__label companyProfile__mt">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Classes
                  </div>
                  {/* 1 */}
                  {currentPendingUserProfile.classes &&
                    currentPendingUserProfile.classes.map((el, id) => {
                      return (
                        <>
                          <div className="profileInfo__classes cap">
                            {el.name}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Grid__Loader />
        )}
      </div>
    </>
  ) : (
    <Spinner />
  );
};

Index.proTypes = {
  // get Classes List data
  getPendingUserData: PropTypes.func.isRequired,
  userAccessKeyApi: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentPendingUserProfile: state.pendingProfile.currentPendingUserProfile,
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPendingUserData,
  userAccessKeyApi,
})(Index);
