import React, { useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { otps, register,registerRedirect } from "actions/adminAuth";
import * as Constants from "constants/index";
import Errors from "../../Notifications/Errors";
import { Button, Form, Input } from "reactstrap";
import Alert from "../../Notifications/Alert";
import OTP_IMG from "assets/otp/otpImg.svg";
import logo from "assets/login/logo.svg";
import arrRight from "assets/login/arrRight.svg";
import viewIcon from "assets/login/eye.svg";
import hideIcon from "assets/login/eyeHide.svg";


const OTP = ({ otps, register,registerRedirect, errorList, loading, history }) => {
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
  });

  const [formData2, setFormData2] = useState({
    name: "",
    email2: "",
    phone: "",
    password: "",
    reporting_framework: "",
  });


  const [toggle, setToggle] = useState(true);
  const clickToggle = () => {
    setToggle(!toggle);
  };
  
  const { email, otp } = formData;
  const { name, email2, phone, password, reporting_framework } = formData2;
  
  const dataOTP = JSON.parse(localStorage.getItem("resendOTP"));
  const changeEmail = dataOTP.email

  const onChange = (e) => {
    const dataOTP = JSON.parse(localStorage.getItem("resendOTP"));
    setFormData({ email: dataOTP.email, otp: e.target.value });
  };

  const resend = (e) => {
    e.preventDefault();
    const dataOTP = JSON.parse(localStorage.getItem("resendOTP"));
    setFormData2({
      name: dataOTP.name,
      email2: dataOTP.email,
      phone: dataOTP.phone,
      password: dataOTP.password,
      reporting_framework: dataOTP.reporting_framework,
    });
    if(name, email2, phone, password, reporting_framework){
      onSubmitOTP(e);
    }
  };
  const onSubmitOTP = (e) => {
    e.preventDefault();
    register(name, email2, phone, password, reporting_framework, history);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    otps(email, otp, history);
  };

  
  return (
    <>
      <div className="otp__story">
        <img className="otp__img" src={OTP_IMG} alt="otp main image" />
        <img className="otp__img--2" src={logo} alt="otp page logo with name" />
      </div>
      <div className="otp__content">
        <Form className="otp__box" onSubmit={(e) => onSubmit(e)}>
          <h2 className="heading-2 mb-md">Verify Your Account</h2>
          <p className="">An OTP has been sent to your {changeEmail}</p>
          <Link
            to={`/app/admin/register`}
            className="font-12 c__white border__bottom mb-md-4"  onClick={(e) => registerRedirect(history)}
          >
            change E-mail
          </Link>
          <Alert />
          <div className="mb-md mt-md-4">
            <div className="otp__resends">
              <label
                htmlFor="otp"
                className="form__label"
                aria-hidden="true"
              >
                Enter OTP here
              </label>
              <p className="otp__resend" onClick={(e) => resend(e)}>
                Resend
              </p>
            </div>
            <div className="inputs__icons">

            <Input
              className="form__input"
              type={toggle ? "password" : "text"}
              name="otp"
              autoFocus
              maxLength="4"
              minLength="4"
              aria-describedby="Enter OTP"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => onChange(e)}
              // invalid={errorList.otp ? true : false}
              required
            />

            <span className="inputs__svg cursor" onClick={clickToggle}>
              {toggle ? (
                <img src={viewIcon} alt="date icon svg" />
              ) : (
                <img src={hideIcon} alt="date icon svg" />
              )}
            </span>
          </div>
            <span
              className="form__label--error"
              role="alert"
              aria-live="assertive"
            >
              <Errors current_key="otp" key="otp" />
              {/* Required otp */}
            </span>
          </div>
          <div className="depricaition__btns">

          <Button className="btn btn-svg otp__btn" type="submit">
            Verify
            <img src={arrRight} />{" "}
          </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

OTP.proTypes = {
  register: PropTypes.func.isRequired,
  otps: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  registerRedirect: PropTypes.func.isRequired,

};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  register,registerRedirect,
  otps,
})(OTP);
