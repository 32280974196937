//alert constants
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//errors constnats
export const SET_ERRORS_LIST = "SET_ERRORS_LIST";
export const REMOVE_ERRORS = "REMOVE_ERRORS";

//Auth Constants
export const SELF_LOADED = "SELF_LOADED";
export const SELF_ERROR = "SELF_ERROR";
// export const USER_LOADED = "USER_LOADED";
// export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

//login/logout, auth,  constnats
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOADING_ON_AUTH_SUBMIT = "LOADING_ON_AUTH_SUBMIT";

// REGISTER, auth,  constnats
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOADING_ON_REGISTER_SUBMIT = "LOADING_ON_REGISTER_SUBMIT";

// OTP, auth,  constnats
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAIL = "OTP_FAIL";
export const LOADING_ON_OTP_SUBMIT = "LOADING_ON_OTP_SUBMIT";

// Reset link
export const RESET_LINK_SUCCESS = "RESET_LINK_SUCCESS";
export const RESET_LINK_FAIL = "RESET_LINK_FAIL";

// Reset Password
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "  RESET_PASSWORD_FAIL";

// setup address
export const SETUP_CREATED = "SETUP_CREATED";
export const SETUP_UPDATED = "SETUP_UPDATED";
export const LOADING_ON_SETUP_SUBMIT = "LOADING_ON_SETUP_SUBMIT";
export const SETUP_ERROR = "SETUP_ERROR";

export const PENDING_UPDATED = "PENDING_UPDATED";
export const PENDING_DELETE = "PENDING_DELETE";
export const LOADING_ON_PENDING_SUBMIT = "LOADING_ON_PENDING_SUBMIT";
export const GET_PENDING_BY_ID = "GET_PENDING_BY_ID";
export const PENDING_ERROR = "PENDING_ERROR";
export const PENDING_LIST = "PENDING_LIST";

// UNVERIFIED
export const UNVERIFIED_UPDATED = "UNVERIFIED_UPDATED";
export const UNVERIFIED_DELETE = "UNVERIFIED_DELETE";
export const LOADING_ON_UNVERIFIED_SUBMIT = "LOADING_ON_UNVERIFIED_SUBMIT";
export const GET_UNVERIFIED_BY_ID = "GET_UNVERIFIED_BY_ID";
export const UNVERIFIED_ERROR = "UNVERIFIED_ERROR";
export const UNVERIFIED_LIST = "UNVERIFIED_LIST";

export const LOADING_ON_PENDING_USER_SUBMIT = "LOADING_ON_PENDING_USER_SUBMIT";
export const PENDING_USER_ERROR = "PENDING_USER_ERROR";
export const GET_PENDING_USER_BY_ID = "GET_PENDING_USER_BY_ID";

// DASHBOARD
export const DASHBOARD_LIST = "DASHBOARD_LIST";
export const LOADING_ON_DASHBOARD_SUBMIT = "LOADING_ON_DASHBOARD_SUBMIT";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const DASHBOARD_OBJECT = "DASHBOARD_OBJECT";

//  ADMIN address
export const ADMIN_CREATED = "  ADMIN_CREATED";
export const ADMIN_UPDATED = "  ADMIN_UPDATED";
export const LOADING_ON_ADMIN_SUBMIT = "LOADING_ON_ ADMIN_SUBMIT";
export const ADMIN_ERROR = "  ADMIN_ERROR";

//  USER address
export const USER_CREATED = "  USER_CREATED";
export const USER_UPDATED = "  USER_UPDATED";
export const LOADING_ON_USER_SUBMIT = "LOADING_ON_ USER_SUBMIT";
export const USER_ERROR = "  USER_ERROR";
