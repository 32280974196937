import { combineReducers } from "redux";
import auth from "./adminAuth";
import alert from "./alert";
import errors from "./errors";
import pending from "./pending";
import pendingProfile from "./getPendingUserProfile";
import unverified from "./unverified";
import dashboard from "./dashboard";
import admin from "./admin";

export default combineReducers({
  auth,
  alert,
  errors,
  pending,
  pendingProfile,
  unverified,
  dashboard,
  admin,
});
