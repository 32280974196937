import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  login,
  forgotPasswordRedirect,
  registerRedirect,
} from "actions/adminAuth";
import { Button, Form, Input } from "reactstrap";
import "./index.scss";
import Alert from "../../Notifications/Alert";
import loginMain from "assets/login/login_main.svg";
import logo from "assets/login/logo.svg";
import hideIcon from "assets/login/eyeHide.svg";
import viewIcon from "assets/login/eye.svg";
import arrRight from "assets/login/arrRight.svg";

const Login = ({
  login,
  forgotPasswordRedirect,
  registerRedirect,
  errorList,
  loading,
  history,
}) => {
  const [formData, setFormData] = useState({ email: "", password: "" });

  const [toggle, setToggle] = useState(true);
  const clickToggle = () => {
    setToggle(!toggle);
  };

  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState("");

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.split(" ").join(""),
    });

    if (e.target.name === "email") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.split(" ").join("").toLowerCase(),
      });
      validateEmail(e);
    }
  };

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateEmail = (event) => {
    const email = event.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
      setMessage("Your email looks good!");
    } else {
      setIsValid(false);
      setMessage("Please enter a valid email!");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password, history);
  };

  return (
    <>
      <div className="login__story">
        <img className="login__img" src={loginMain} alt="login main image" />
        <div className="login__text">
          <p className="login__text--size">
            Property, Plant & Equipment Management
          </p>
          <img
            className="login__img--2"
            src={logo}
            alt="login page logo with name"
          />
        </div>
      </div>
      <div className="login__content">
        <Form className="login__box" onSubmit={(e) => onSubmit(e)}>
          <h2 className="heading-2 mb-s-sm">Log in</h2>
          <p className="mb-sm">Welcome Back to Virgilproper</p>

          <Alert />

          <div className="mb-md mt-sm">
            <label htmlFor="email" className="form__label" aria-hidden="true">
              Email
            </label>
            <Input
              className="form__input login__w32"
              type="text"
              name="email"
              autoFocus
              maxLength="50"
              minLength="5"
              aria-describedby="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => onChange(e)}
              invalid={errorList.email ? true : false}
              required
            />
            <span
              className={`login__err form__label--${
                isValid ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {message}
            </span>
          </div>

          <div className="mb-md">
            {/* <label
              htmlFor="password"
              className="form__label"
              aria-hidden="true"
            >
              Password
            </label> */}
            <div className="otp__resends">
              <label
                htmlFor="password"
                className="form__label"
                aria-hidden="true"
              >
                Password
              </label>
              <Link
                to={`/app/admin/enter-registerd-email`}
                className="otp__resend c__white"
                onClick={(e) => forgotPasswordRedirect(history)}
              >
                {/* <p className="otp__resend" onClick={(e) => resend(e)}> */}
                Forgot ?
              </Link>
            </div>

            <div className="inputs__icons">
              <Input
                className="form__input login__w32"
                type={toggle ? "password" : "text"}
                name="password"
                // autoFocus
                maxLength="16"
                minLength="6"
                aria-describedby="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => onChange(e)}
                invalid={errorList.password ? true : false}
                required
              />

              <span className="inputs__svg cursor" onClick={clickToggle}>
                {toggle ? (
                  <img src={viewIcon} alt="date icon svg" />
                ) : (
                  <img src={hideIcon} alt="date icon svg" />
                )}
              </span>
            </div>
          </div>
          {/* {loading ? (
            <Button className="btn btn-svg login__btn" disabled>
              Loading....
              <img src={arrRight} />{" "}
            </Button>
          ) : ( */}
          <Button
            className="btn btn-svg login__btn"
            type="submit"
            disabled={isValid === true ? false : true}
          >
            Log in
            <img src={arrRight} />{" "}
          </Button>
          {/* <span className="form__label--error">
            {" "}
            {isValid === false ? "Invalid Email" : ""}{" "}
          </span> */}

          <p className="mt-md">
            <Link
              to={`/app/admin/register`}
              className="login__link"
              onClick={(e) => registerRedirect(history)}
            >
              New here? Sign up here
            </Link>
          </p>
        </Form>
      </div>
    </>
  );
};

Login.proTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  forgotPasswordRedirect: PropTypes.func.isRequired,
  registerRedirect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  login,
  forgotPasswordRedirect,
  registerRedirect,
})(Login);
