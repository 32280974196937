import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { logout } from "actions/adminAuth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import profile from "assets/navbar/profile.svg";
import logoutSvg from "assets/navbar/logout.svg";
import uparrow from "assets/navbar/down.svg";
import logo from "assets/logo/logo.svg";

const Index = ({ logout }) => {
  const dataSelfApi = JSON.parse(localStorage.getItem("dataSelfApi"));
  useEffect(() => {
    if (dataSelfApi) {
      setUserName(dataSelfApi.name);
      setFormData(dataSelfApi.classes);
    }
  }, [dataSelfApi]);

  const [userName, setUserName] = useState();
  const [formData, setFormData] = useState([]);

  return (
    <div className="topnav">
      <Link className="topnav__logos" to="/app/admin/dashboard">
        <img src={logo} className="topnav__logo--svg" alt="back logo" />
        <p className="topnav__logo--name">Virgilproper</p>
      </Link>

      <div className="container__ul topnav__ul">
        <div className="container__li">
          <Link to="#" className="topnav__dropdown">
            <div className="topnav__bgbox cap">
              {userName && userName.substring(0).slice(0, 1)}
            </div>
            {/* <p className="cap">{userName}</p> */}

            <p className="cap bp__smallLarge__none">{userName}</p>
            <p className="cap bp__min__smallLarge__none">
              {userName && userName.split(" ", 1)}
            </p>
            <img
              src={uparrow}
              className="dropdown__icon"
              alt="up arrow of profile icon"
            />
          </Link>
          <div className="content__ul content__dropdown" aria-label="submenu">
            <div className="content__li">
              <Link to="/app/admin/profile" className="content__links">
                <img
                  src={profile}
                  className="content__logo"
                  alt="Pending Request svg icon"
                />
                <p>Profile</p>
              </Link>
              <div className="content__links" onClick={logout}>
                <img
                  src={logoutSvg}
                  className="content__logo"
                  alt="message svg icon"
                />
                <p>Logout</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Index.proTypes = {
  // logout
  logout: PropTypes.func.isRequired,

  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logout,
})(Index);
