export const Btn__Loader = () => {
  return (
    <div className="btn__loader__box">
      <div className="btn__loader"></div>
    </div>
  );
};
export const Screen__Loader = () => {
  return (
    <div className="screen__loader__container">
      <div className="screen__loader__box">
        <div className="screen__loader">
          <div className="screen__loader__position"></div>
        </div>
      </div>
    </div>
  );
};
export const Grid__Loader = () => {
  return (
    // <div className="grid__loader__container">
    <div className="grid__loader__box">
      <div className="screen__loader">
        <div className="grid__loader__position"></div>
      </div>
    </div>
    // </div>
  );
};
