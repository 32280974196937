import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import Spinner from "views/Spinner";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPendingUserData } from "actions/getPendingUserProfileData";
import { approveUserPageInfo, rejectPendingUser } from "actions/pending";
import {
  Btn__Loader,
  Screen__Loader,
  Grid__Loader,
} from "../../../ReusableComponents/Loader/index";
import "./index.scss";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";

const Index = ({
  approveUserPageInfo,
  rejectPendingUser,
  getPendingUserData,
  currentPendingUserProfile,
  match,
  errorList,
  loading,
  history,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const pending_user_id = queryParams.get("id");
  const [isGridLoader, setIsGridLoader] = useState(false);
  const [isBtnRejectLoader, setIsBtnRejectLoader] = useState(false);
  const [isBtnApproveLoader, setIsBtnApproveLoader] = useState(false);
  const [isScreenLoader, setIsScreenLoader] = useState(false);

  useEffect(() => {
    setIsGridLoader(true);
    getPendingUserData(pending_user_id).then((res) => {
      if (res === undefined) {
        setIsGridLoader(false);
      } else {
        setIsGridLoader(false);
      }
    });
  }, [pending_user_id]);

  const approve_user = () => {
    setIsBtnApproveLoader(true);
    setIsScreenLoader(true);
    const active_status = 1;
    const user_id = currentPendingUserProfile.id;
    const user_name = currentPendingUserProfile.name;
    const user_email = currentPendingUserProfile.email;
    approveUserPageInfo(
      user_id,
      user_name,
      user_email,
      active_status,
      history
    ).then((res) => {
      if (res === undefined) {
        setIsBtnApproveLoader(false);
        setIsScreenLoader(false);
      } else {
        setIsBtnApproveLoader(false);
        setIsScreenLoader(false);
      }
    });
    // approveUserPageInfo(user_id, user_name, user_email, active_status, history);
  };

  const delete_user = () => {
    setIsBtnRejectLoader(true);
    setIsScreenLoader(true);
    const user_id = currentPendingUserProfile.id;
    const status_reject = 3;
    rejectPendingUser(user_id, status_reject, history).then((res) => {
      if (res === undefined) {
        isBtnRejectLoader(false);
        setIsScreenLoader(false);
      } else {
        isBtnRejectLoader(false);
        setIsScreenLoader(false);
      }
    });
    // rejectPendingUser(user_id, status_reject, history);
  };

  //cancel form submit
  const onClickHandel = (e) => {
    e.preventDefault();
    // cancelSave(history);
    history.goBack();
  };

  return (
    <>
      <TopNav />
      <Sidenav />
      <div className="listings listings__loaderPosition">
        {isGridLoader == false && isScreenLoader == false ? (
          <>
            <div className="listing__breadcrum">
              <span className="font-14 ">
                <Link className="op-2" to="/app/admin/dashboard">
                  Home/ Dashboard/{" "}
                </Link>
                <span className="c__blue">Request Info</span>
              </span>
            </div>
            <div className="listing info__container">
              <div className="companyProfile__header">
                <h3 className="heading-3 mb-md">Request Info</h3>
              </div>
              <div className="companyProfile__labels">
                <div className="companyProfile__label">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Name
                  </div>
                  <div className="companyProfile__label-data cap">
                    {currentPendingUserProfile.name
                      ? currentPendingUserProfile.name
                      : ""}
                  </div>
                </div>
                <div className="companyProfile__label">
                  <div className="companyProfile__label-name c__9b weight-700">
                    Email
                  </div>
                  <div className="companyProfile__label-data lower">
                    {currentPendingUserProfile.email
                      ? currentPendingUserProfile.email
                      : ""}
                  </div>
                </div>

                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Phone Number
                    </div>
                    <div className="companyProfile__label-data ">
                      {currentPendingUserProfile.phone
                        ? currentPendingUserProfile.phone
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Date Applied
                    </div>
                    <div className="companyProfile__label-data ">
                      {currentPendingUserProfile.created_on
                        ? currentPendingUserProfile.created_on
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="physical__box">
                  <div className="companyProfile__label companyProfile__mt">
                    <div className="companyProfile__label-name c__9b weight-700">
                      Framework
                    </div>
                    <div className="companyProfile__label-data upper">
                      {currentPendingUserProfile.organization &&
                      currentPendingUserProfile.organization.reporting_framework
                        ? currentPendingUserProfile.organization
                            .reporting_framework
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className="info__btns">
                <Link
                  to="/app/admin/request-pending-list"
                  className="btn popup__btn btn__border update"
                  // onChange={history.goBack()}
                >
                  Cancel
                </Link>
                {isBtnRejectLoader == true ? (
                  <Button className="btn popup__btn btn__border info__btn_orange update">
                    <Btn__Loader />
                  </Button>
                ) : (
                  <Button
                    className="btn popup__btn btn__border info__btn_orange update"
                    onClick={() => {
                      delete_user();
                    }}
                  >
                    Reject
                  </Button>
                )}
                {isBtnApproveLoader == true ? (
                  <Button className="btn popup__btn btn__blue cap">
                    <Btn__Loader />
                  </Button>
                ) : (
                  <Button
                    className="btn popup__btn btn__blue cap"
                    onClick={() => {
                      approve_user();
                    }}
                  >
                    Approve
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <Grid__Loader />
        )}
      </div>
    </>
  );
};

Index.propTypes = {
  // rejectPendingUser
  rejectPendingUser: PropTypes.object.isRequired,
  // approveUserPageInfo
  approveUserPageInfo: PropTypes.object.isRequired,
  // get Classes List data
  getPendingUserData: PropTypes.func.isRequired,
  // loading
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  currentPendingUserProfile: state.pendingProfile.currentPendingUserProfile,
  auth: state.auth,
  loading: state.pendingProfile.loading,
});

export default connect(mapStateToProps, {
  approveUserPageInfo,
  rejectPendingUser,
  getPendingUserData,
})(withRouter(Index));
