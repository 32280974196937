import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import { getInactiveUserList, approveUser } from "actions/pending";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";
import eye from "assets/land/eye.svg";
import activeTic from "assets/land/active.svg";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: "1.5rem",
      textTransform: "capitalize",
      paddingLeft: ".5rem", // override the cell padding for head cells
      paddingRight: ".5rem",
    },
  },
  rows: {
    style: {
      minHeight: "3rem", // override the row height
    },
  },
  cells: {
    style: {
      paddingLeft: ".5rem", // override the cell padding for data cells
      paddingRight: ".5rem",
    },
  },
};

const Index = ({
  approveUser,
  getInactiveUserList,
  pendingList,
  match,
  history,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const name = queryParams.get("name");
  const class_id = queryParams.get("id");

  // useEffect(() => {
  //   getInactiveUserList();
  // }, []);

  const [pending, setPending] = useState(true);
  useEffect(() => {
    getInactiveUserList().then((res) => {
      if (res === undefined) {
        setPending(false);
      } else {
        setPending(false);
      }
    });
  }, [getInactiveUserList]);

  //cancel form submit
  const onClickHandel = (e) => {
    e.preventDefault();
    // cancelSave(history);
  };

  //////////////////SEARCHING/FILTER///////////////////

  const [searchs, setNewSearch] = useState("");

  const handleSearchChange = (e) => {
    setNewSearch(e.target.value);
  };

  const filtered = !searchs
    ? pendingList
    : pendingList.filter(
        (el) =>
          el.name.toLowerCase().includes(searchs.toLowerCase()) ||
          el.email.toLowerCase().includes(searchs.toLowerCase()) ||
          el.phone.toLowerCase().includes(searchs.toLowerCase()) ||
          el.created_on.toLowerCase().includes(searchs.toLowerCase())
      );
  //////////////////SEARCHING/FILTER///////////////////
  const columns = [
    {
      name: "Sr",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "4rem",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      grow: 2,
    },
    {
      name: "Accountant",
      selector: (row) => row.accountant_count,
      sortable: true,
    },
    {
      name: "Classes",
      selector: (row) => row.class_count,
      sortable: true,
    },
    {
      name: "Assets",
      selector: (row) => row.asset_count,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.action,
      center: true,
      cell: (row) => {
        return (
          <>
            <div className="tooltip">
              <span className="tooltiptext">View</span>
              <Link
                to={`/app/admin/inactive-user-profile?name=${row.name}&id=${row.id}`}
              >
                <img className="cursor" src={eye} alt="edit status" />
              </Link>
            </div>
            <div className="tooltip">
              <span className="tooltiptext">Activate user</span>
              <Link className=" ml-s-sm">
                <img
                  className="cursor"
                  src={activeTic}
                  onClick={() => {
                    print(row);
                  }}
                  alt="edit status"
                />
              </Link>
            </div>
          </>
        );
      },
    },
  ];

  const print = (row) => {
    const active_status = 1;
    approveUser(row.id, row.name, row.email, active_status, history);
  };
  return (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings">
        <div className="listing__breadcrum">
          <span className="font-14 ">
            <Link className="op-2" to="/app/admin/dashboard">
              Home/ Dashboard/{" "}
            </Link>
            <span className="c__blue">{name}Inactive Users</span>
          </span>
        </div>
        <div className="listing">
          <div className="listing__header mb-md">
            <h3 className="heading-3 cap">{name}Inactive Users</h3>
            <div className="listing__right">
              <Input
                className="form__search"
                type="search"
                name="search"
                placeholder="Search..."
                value={searchs}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <DataTable
            columns={columns}
            data={filtered}
            // data={data}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
    </>
  );
};

Index.proTypes = {
  getInactiveUserList: PropTypes.object.isRequired,
  pendingList: PropTypes.array.isRequired,
  approveUser: PropTypes.object.isRequired,

  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  pendingList: state.pending.pendingList,
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getInactiveUserList,
  approveUser,
})(Index);
