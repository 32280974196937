import {
    LOADING_ON_PENDING_USER_SUBMIT,
    PENDING_USER_ERROR,
    GET_PENDING_USER_BY_ID,
  } from "../actions/types";
  
  const initalState = {
    assetList: [],
    currentPendingUserProfile: {},
    loading: true,
    listLoading: true,
    error: {},
  };
  
  export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case LOADING_ON_PENDING_USER_SUBMIT:
        return {
          ...state,
          loading: true,
        };
      case PENDING_USER_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      case GET_PENDING_USER_BY_ID:
        return {
          ...state,
          currentPendingUserProfile: payload,
          loading: false,
        };
      default:
        return state;
    }
  }
  