import axios from "axios";
import { setAlert, removeAlert } from "./alert";
import { setErrorsList } from "./errors";
import {
  LOADING_ON_AUTH_SUBMIT,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  OTP_SUCCESS,
  OTP_FAIL,
  SELF_LOADED,
  SELF_ERROR,
  REMOVE_ERRORS,
  REMOVE_ALERT,
  RESET_LINK_FAIL,
  RESET_LINK_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
} from "./types";
import * as Constants from 'constants/index';

const headerOptions1 = {
  headers: {
    "Content-Type": "application/json",
  },
};

// Dispatch Loading
export const loadingOnSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_AUTH_SUBMIT });
};

// selfauth Update
export const selfAuth = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };
  try {
    const { data } = await axios.get(`${Constants.API_BASE_URL}/admin/self`, config);
    localStorage.setItem("dataSelfApi", JSON.stringify(data.data));
    if (data.status === 200) {
      dispatch({
        type: SELF_LOADED,
        payload: data.data,
      });
    } else {
      window.localStorage.clear();
      dispatch({ type: LOGOUT });
    }
  } catch (err) {
    window.localStorage.clear();
    dispatch({ type: LOGOUT });
    // return undefined;
  }
};

//Login
export const login = (email, password, history) => async (dispatch) => {
  const body = JSON.stringify({ email, password });
  dispatch(loadingOnSubmit());
  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });

    const res = await axios.post(
      `${Constants.API_BASE_URL}/login/admin`,
      body,
      headerOptions1
    );
    if (res.data.status === 200) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data.auth_key,
      });
      dispatch(selfAuth());
      history.push(`/app/admin/dashboard`);
      dispatch(setAlert("You are logged in successfully.", "success"));
      selfAuth();
    }
  } catch (err) {
    dispatch({ type: LOGIN_FAIL });

    var condition = navigator.onLine ? "online" : "offline";
    if (condition == "offline") {
      dispatch(
        setAlert(
          `Oops! Please check your internet connection or try again later`,
          "danger"
        )
      );
    }

    if (err.response.data.status === 401) {
      const errors = err.response.data.message;
      if (
        errors ===
        "Your account is awaiting approval. Once approved you will receive an email!"
      ) {
        history.push(`/app/admin/gaap-message`);
      }
      dispatch(setAlert(errors, "danger"));
    }
    dispatch({
      type: LOGIN_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });

    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    const errors = err.response.data.message;
    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(setAlert(`${error.msg}, ${error.param}`, "danger"));
      });
    }
    return undefined;
  }
};

//OTPs
export const otps = (email, otp, history) => async (dispatch) => {
  const body = JSON.stringify({ email, otp });
  dispatch(loadingOnSubmit());
  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });

    const res = await axios.post(
      `${Constants.API_BASE_URL}/register/user`,
      body,
      headerOptions1
    );
    if (res.data.status === 200) {
      dispatch({
        type: OTP_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert(`${res.data.message}`, "success"));
      window.localStorage.clear();
      history.push(`/app/admin/login`);
      dispatch(removeAlert());
      dispatch({ type: REMOVE_ERRORS });
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch({ type: OTP_FAIL });
      const errors = res.data.errors;
      if (errors.length > 0) {
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response.data.status === 401) {
      dispatch(setAlert(err.response.data.message, "danger"));
    } else {
      dispatch(setAlert(err.response.data.message[0].msg, "danger"));
    }
    dispatch({
      type: OTP_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Generate Reset Link
export const resetLink = (formData, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(formData);
  dispatch(loadingOnSubmit());
  dispatch({ type: REMOVE_ERRORS });

  try {
    const res = await axios.post(
      `${Constants.API_BASE_URL}/register/user-forgot`,
      body,
      config
    );
    if (res.data.status === 200) {
      dispatch({
        type: RESET_LINK_SUCCESS,
        payload: res.data,
      });
      dispatch(
        setAlert(
          `An  OTP has been sent to your email ${formData.email}!`,
          "success"
        )
      );
      history.push(`/app/admin/reset-password`);
      if (formData.email) {
        const emailData = formData.email;
        localStorage.setItem("resetEmail", JSON.stringify(emailData));
      }
    } else {
      dispatch(setAlert(res.message, "danger"));
      dispatch({ type: RESET_LINK_FAIL });
      const errors = res.data.errors;
      if (errors.length > 0) {
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response.data.status === 401) {
      const errors = err.response.data.message;
      dispatch(setAlert(errors, "danger"));
    }
    dispatch({
      type: RESET_LINK_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    return undefined;
  }
};

//  Reset Password
export const resetPassword =
  (email, otp, password, history) => async (dispatch) => {
    dispatch(loadingOnSubmit());
    dispatch({ type: REMOVE_ERRORS });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email, otp, password });

    try {
      const res = await axios.post(
        `${Constants.API_BASE_URL}/register/user-reset`,
        body,
        config
      );
      if (res.data.status === 200) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: res.data,
        });
        dispatch(setAlert(`${res.data.message}`, "success"));
        window.localStorage.clear();
        history.push(`/app/admin/login`);
      }
    } catch (err) {
      if (err.response.data.status === 401) {
        const errors = err.response.data.message;
        dispatch(setAlert(errors, "danger"));
      }
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      return undefined;
    }
  };

//Register User
export const register = (formData, history) => async (dispatch) => {
  console.log("register", formData);
  const body = JSON.stringify({
    name: formData.name,
    email: formData.email,
    password: formData.password,
  });

  dispatch(loadingOnSubmit());

  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.post(`${Constants.API_BASE_URL}/admin`, body, headerOptions1);
    if (res.data.status) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.response,
      });
      dispatch(setAlert(res.data.message, "success"));
      history.push(`/app/admin/dashboard`);
      // history.push(`/app/admin/otp`);
    } else {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.response,
      });
      const errors = res.data.errors;
      if (errors.length > 0) {
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      } else {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
  } catch (err) {
    if (err.response.data.status === 401) {
      if (err.response.data.message === "Contact Virgil Financial Services") {
        history.push(`/app/admin/gaap-message`);
        window.localStorage.clear();
      } else if (
        err.response.data.message ===
        "I am already have similar record, guess you are duplicating!"
      ) {
      }
      dispatch(setAlert(err.response.data.message[0].msg, "danger"));
    } else if (err.response.data.message.length >= 0) {
      return dispatch(setAlert(err.response.data.message[0].msg, "danger"));
    } else {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    if (err !== undefined) {
      // if(err.response.data.message.length >= 0){
      //   return dispatch(setAlert(err.response.data.message[0].msg, "danger"));
      // }
      dispatch(setAlert(err.response.data.message, "danger"));
      dispatch({
        type: REGISTER_FAIL,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

// Redirect Login
export const loginRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push(`/app/admin/login`);
};
// Redirect Register
export const registerRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push(`/app/admin/register`);
};

// Redirect to forgot password screen
export const forgotPasswordRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push(`/app/admin/enter-registerd-email`);
};

// Token invalid
export const tokenInvalid = (history) => async (dispatch) => {
  history.push(`/app/admin/forgot-password`);
};

//Dispatch Confirm password error
export const setPasswordError = (msg, param) => async (dispatch) => {
  dispatch(setErrorsList(msg, param));
};

// Logout
export const logout = () => async (dispatch) => {
  window.localStorage.clear();
  dispatch({ type: LOGOUT });
  dispatch(removeAlert());
};
