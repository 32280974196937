import {
  UNVERIFIED_LIST,
  UNVERIFIED_UPDATED,
  LOADING_ON_UNVERIFIED_SUBMIT,
  UNVERIFIED_ERROR,
  GET_UNVERIFIED_BY_ID,
} from "actions/types";

const initalState = {
  unverifiedList: [],
  currentUnverified: {},
  loading: true,
  listLoading: true,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_ON_UNVERIFIED_SUBMIT:
      return {
        ...state,
        loading: true,
      };
    case UNVERIFIED_LIST:
      return {
        ...state,
        listLoading: false,
        unverifiedList: payload,
      };
    case UNVERIFIED_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_UNVERIFIED_BY_ID:
      return {
        ...state,
        currentUnverified: payload,
        loading: false,
      };
    case UNVERIFIED_UPDATED:
      return {
        ...state,
        currentUnverified: payload,
        loading: false,
      };
    default:
      return state;
  }
}
