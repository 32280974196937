import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "react-tabs/style/react-tabs.scss";
import "./index.scss";
import { getDashboard } from "actions/dashboard";
import TopNav from "../Navbars/TopNav/index";
import Sidenav from "../Navbars/Sidebar/index";
import account from "assets/dashboard/account.svg";
import workingAsset from "assets/dashboard/workingAsset.svg";
import nonWorkAsset from "assets/dashboard/nonWorkAsset.svg";
import { Line, Pie, measureTextWidth } from "@ant-design/plots";

const Index = ({
  getDashboard,
  dashboardList,
  currentDashboard,
  loading,
  history,
}) => {
  const [formData, setFormData] = useState({
    isAccountant: "",
    isAsset: "",
    isWorkingAsset: "",
    isNonworkingAsset: "",
    isRecentEditsData: [],
    isAssetAllocationData: [],
  });
  useEffect(() => {
    getDashboard().then((res) => {
      if (res === undefined) {
        // notFound(history);
      } else {
        setFormData({
          isAccountant:
            loading || !res.tilesData.totalUsers
              ? "0"
              : res.tilesData.totalUsers,
          isAsset:
            loading || !res.tilesData.totalRequest
              ? "0"
              : res.tilesData.totalRequest,
          isWorkingAsset:
            loading || !res.tilesData.totalWorkingAsset
              ? "0"
              : res.tilesData.totalWorkingAsset,
          isNonworkingAsset:
            loading || !res.tilesData.totalNonWorkingAsset
              ? "0"
              : res.tilesData.totalNonWorkingAsset,
          isRecentEditsData:
            loading || !res.recentEditsData ? "" : res.recentEditsData,
          isAssetAllocationData:
            loading || !res.assetAllocationData.assetAllocationData
              ? ""
              : res.assetAllocationData.assetAllocationData,
        });
        setData(res.classTrends);
      }
    });
  }, []);
  const [data, setData] = useState([]);

  const classTrend = {
    data,
    xField: "month",
    yField: "assetCount",
    seriesField: "class",
    // yAxis: {
    //   label: {
    //     formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
    //   },
    // },
    legend: {
      position: "right",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }

  const pieData = [
    { class: "land", totalAsset: 0, asset_id: 21 },
    { class: "buildings", totalAsset: 0, asset_id: 20 },
    { class: "computers", totalAsset: 0, asset_id: 22 },
    { class: "office equipment", totalAsset: 0, asset_id: 23 },
    { class: "infrastructure", totalAsset: 0, asset_id: 24 },
    { class: "motor vehicles", totalAsset: 0, asset_id: 29 },
    { class: "plant and machinery", totalAsset: 0, asset_id: 28 },
    { class: "heritage assets", totalAsset: 0, asset_id: 27 },
    { class: "biological assets", totalAsset: 0, asset_id: 25 },
    { class: "other", totalAsset: 0, asset_id: 26 },
  ];

  let config;
  if (formData.isAssetAllocationData.length > 0) {
    config = {
      appendPadding: 10,
      data: formData.isAssetAllocationData,
      angleField: "totalAsset",
      colorField: "class",
      radius: 1,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v) => `${v}`,
        },
      },
      legend: {
        position: "left",
      },
      label: {
        type: "inner",
        offset: "-50%",
        style: {
          textAlign: "center",
        },
        autoRotate: false,
        content: "{value}",
      },
      statistic: {
        title: {
          offsetY: 4,
          style: {
            fontSize: "32px",
            fontWeight: "bold",
          },
          customHtml: (container, view, datum, data) => {
            const { width } = container.getBoundingClientRect();
            const text = datum
              ? `${datum.totalAsset}`
              : `${data.reduce((r, d) => r + d.totalAsset, 0)}`; //value
            return renderStatistic(width, text, {
              fontSize: 32,
            });
          },
        },
        content: {
          offsetY: -4,
          style: {
            fontSize: "12px",
            marginTop: "20px",
          },
          customHtml: (container, view, datum) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(
              Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
            );
            const text = datum ? datum.class : "Total Asset";
            return renderStatistic(d, text, {
              fontSize: 28,
            });
          },
        },
      },
      //
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
        {
          type: "pie-statistic-active",
        },
      ],
    };
  } else {
    config = {
      appendPadding: 10,
      data: pieData,
      angleField: "totalAsset",
      colorField: "class",
      radius: 1,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v) => `${v}`,
        },
      },
      legend: {
        position: "left",
      },
      label: {
        type: "inner",
        offset: "-50%",
        style: {
          textAlign: "center",
        },
        autoRotate: false,
        content: "{value}",
      },
      statistic: {
        title: {
          offsetY: 4,
          style: {
            fontSize: "32px",
            fontWeight: "bold",
          },
          customHtml: (container, view, datum, data) => {
            const { width } = container.getBoundingClientRect();
            const text = datum
              ? `${datum.totalAsset}`
              : `${data.reduce((r, d) => r + d.totalAsset, 0)}`; //value
            return renderStatistic(width, text, {
              fontSize: 32,
            });
          },
        },
        content: {
          offsetY: -4,
          style: {
            fontSize: "12px",
            marginTop: "20px",
          },
          customHtml: (container, view, datum) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(
              Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
            );
            const text = datum ? datum.class : "Total Asset";
            return renderStatistic(d, text, {
              fontSize: 28,
            });
          },
        },
      },
      //
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
        {
          type: "pie-statistic-active",
        },
      ],
    };
  }

  return (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings">
        <div className="listing__breadcrum">
          <span className="font-14 op-2">
            Home/ <span className="c__blue"> Dashboard</span>
          </span>
        </div>

        <div className="dashboard__cards">
          {/* card */}
          <div className="dashboard__card">
            <div className="dashboard__story dashboard__story--1">
              <div className="dashboard__circle dashboard__circle--1">
                <img src={account} className="dashboard__svg" />
              </div>
            </div>
            <div className="dashboard__content">
              <p> Active Account</p>
              <h2 className="heading-2">{formData.isAccountant}</h2>
            </div>
          </div>
          <div className="dashboard__card">
            <div className="dashboard__story dashboard__story--2">
              <div className="dashboard__circle dashboard__circle--2">
                <img src={account} className="dashboard__svg" />
              </div>
            </div>
            <div className="dashboard__content">
              <p>Pending Request</p>
              <h2 className="heading-2 upper">{formData.isAsset}</h2>
            </div>
          </div>
          <div className="dashboard__card">
            <div className="dashboard__story dashboard__story--3">
              <div className="dashboard__circle dashboard__circle--3">
                <img src={workingAsset} className="dashboard__svg" />
              </div>
            </div>
            <div className="dashboard__content">
              <p>Working Assets</p>
              <h2 className="heading-2 upper">{formData.isWorkingAsset}</h2>
            </div>
          </div>
          <div className="dashboard__card">
            <div className="dashboard__story dashboard__story--4">
              <div className="dashboard__circle dashboard__circle--4">
                <img src={nonWorkAsset} className="dashboard__svg" />
              </div>
            </div>
            <div className="dashboard__content">
              <p>Non-Working Assets</p>
              <h2 className="heading-2 upper">{formData.isNonworkingAsset}</h2>
            </div>
          </div>
        </div>

        <div className="listing">
          <div className="listing__heade">
            <h3 className="heading-3 mb-md">Class Trend </h3>
            <div className="dashboard_graph--line">
              <Line {...classTrend} />
            </div>
          </div>
        </div>
        <div className="dashboards">
          <div className="dashboards__cards">
            <div className="dashboards__card">
              <h3 className="heading-3 weight-700">Recent Edits</h3>
              {/* title */}
              <p className="dashboards__end  cap">Last 5 Days </p>
              {/* <div className="dashboards__underline"></div>
              <p className="cap">
                {formData.isRecentEditsData &&
                formData.isRecentEditsData[0] &&
                formData.isRecentEditsData[0].created_on
                  ? formData.isRecentEditsData[0].created_on.slice(2)
                  : ""}
              </p> */}
              <div className="dashboards__underline"></div>
              {formData.isRecentEditsData &&
                formData.isRecentEditsData.map((el) => {
                  return (
                    <>
                      <div className="dashboards__dots">
                        <div className="dashboards__dot dashboards__dot--blue"></div>
                        <p className="dashboards__blueBg cap">
                          {el.created_on}
                        </p>
                      </div>

                      <div className="dashboards__dots dashboards__end">
                        <div className="dashboards__dot dashboards__dot--blue"></div>
                        <p className=" cap">{el.user_name}</p>
                      </div>
                      <p className="dashboards__subFull">
                        Asset Id {el.asset_id}
                      </p>
                      <p className="dashboards__subFull dashboards__gray">
                        {el.message}
                      </p>
                      <div className="dashboards__underline"></div>
                    </>
                  );
                })}
            </div>
          </div>
          <div className="dashboards__cards">
            <p className="weight-700 cap">Asset Allocation </p>
            <Pie {...config} />
          </div>
        </div>
      </div>
    </>
  );
};

Index.proTypes = {
  // getDashboard class
  getDashboard: PropTypes.func.isRequired,
  // list
  dashboardList: PropTypes.array.isRequired,
  currentDashboard: PropTypes.object.isRequired,
  //
  errorList: PropTypes.object.isRequired,
  // loading
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  dashboardList: state.dashboard.dashboardList,
  currentDashboard: state.dashboard.currentDashboard,
  loading: state.dashboard.loading,
  // errorList: state.errors,
  // auth: state.auth,
});

export default connect(mapStateToProps, {
  getDashboard,
})(Index);
