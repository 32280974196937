import {
  DASHBOARD_LIST,
  LOADING_ON_DASHBOARD_SUBMIT,
  REMOVE_ERRORS,
  DASHBOARD_OBJECT,
} from "actions/types";

const initalState = {
  dashboardList: [],
  currentDashboard: {},
  loading: false,
  listLoading: true,
  errors: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_ON_DASHBOARD_SUBMIT:
      return {
        ...state,
        loading: true,
      };
      break;
    case DASHBOARD_OBJECT:
      return {
        ...state,
        loading: false,
        currentDashboard: payload,
      };
    case DASHBOARD_LIST:
      return {
        ...state,
        listLoading: false,
        dashboardList: payload,
      };
    case REMOVE_ERRORS:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
