import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";
import PropTypes from "prop-types";
import { register, loginRedirect } from "actions/adminAuth";
import Errors from "../../Notifications/Errors";
import { Button, Form, Input } from "reactstrap";
import Alert from "../../Notifications/Alert";
import loginMain from "assets/login/login_main.svg";
import logo from "assets/login/logo.svg";
import arrRight from "assets/login/arrRight.svg";
import viewIcon from "assets/login/eye.svg";
import hideIcon from "assets/login/eyeHide.svg";

const Register = ({ register, loginRedirect, errorList, loading, history }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    reporting_framework: "",
  });

  const [toggle, setToggle] = useState(true);
  const clickToggle = () => {
    setToggle(!toggle);
  };

  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePhone, setMessagePhone] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [messagePassword, setMessagePassword] = useState("");

  const { name, email, password, reporting_framework } = formData;

  useEffect(() => {
    const dataOTP = JSON.parse(localStorage.getItem("resendOTP"));
    if (dataOTP) {
      setFormData({
        name: dataOTP.name,
        email: dataOTP.email,
        password: dataOTP.password,
      });
    }
  }, []);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.split(" ").join(""),
    });
    // name
    if (e.target.name === "name") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    // email
    if (e.target.name === "email") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.split(" ").join("").toLowerCase(),
      });
      validateEmail(e);
    }
    // password
    if (e.target.name === "password") {
      validatePassword(e);
    }
  };

  // The regular exprssion to validate the email pattern
  // It may not be 100% perfect but can catch most email pattern errors and assures that the form is mostly right
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  // const phoneRegex = /^([+]?[\s0-9]+)?(\d{6}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
  // const phoneRegex = /^(\s)?(\d{6}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
  // const phoneRegex = /^([0-9])$/;

  const phoneRegex = /^[0-9\b]+$/;

  const validateEmail = (event) => {
    const email = event.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
      // setMessage("");
      setMessage("Your email looks good!");
    } else {
      setIsValid(false);
      setMessage("Please enter a valid email!");
    }
  };
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{16,})"
  );
  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );

  const validatePassword = (event) => {
    const password = event.target.value;
    if (strongRegex.test(password)) {
      setIsValidPassword(true);
      setMessagePassword("Your password strength looks stronger!");
    } else if (mediumRegex.test(password)) {
      setIsValidPassword(true);
      setMessagePassword("Your password strength looks better!");
    } else {
      setIsValidPassword(false);
      setMessagePassword("Your password strength looks good!");
    }
  };

  const onSubmit = (e, isValid) => {
    e.preventDefault();

    if (isValid === false) {
      return;
    } else if (isValid === true) {
      if (name && email && password) {
        const registerValue = {
          name: formData.name,
          email: formData.email,
          password: formData.password,
        };
        // localStorage.setItem("registerValue", JSON.stringify(registerValue));
        // history.push("/app/select-financial-framework");
        register(formData, history);
      }
    }
  };

  // Numeric Value only
  const numericOnly = (event) => {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  };

  //handleChange of Usefull-life and Deprecation
  const handleChangePercent = (el) => {
    const name = el.target.name;
    const value = el.target.value;

    switch (name) {
      case "phone":
        if (!Number(value)) {
          return setMessagePhone("OOPs! Only numeric values or digits allowed");
        } else {
          setMessagePhone("");
          setFormData({
            ...formData,
            [name]: value,
            phone: value.split(" ").join(""),
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="register__story">
        <img className="register__img" src={loginMain} alt="login main image" />
        <div className="register__text">
          <p className="register__text--size">
            Property, Plant & Equipment Management
          </p>
          <img
            className="register__img--2"
            src={logo}
            alt="login page logo with name"
          />
        </div>
      </div>
      <div className="register__content">
        <Form className="register__box" onSubmit={(e) => onSubmit(e, isValid)}>
          <h2 className="heading-2 mb-sm">Sign up</h2>
          <p className="mb-sm">Welcome to Virgilproper</p>
          <Alert />
          <div className="mb-sm mt-s-sm">
            <label htmlFor="name" className="form__label " aria-hidden="true">
              Name
            </label>
            <Input
              className="form__input register__w32"
              type="text"
              name="name"
              autoFocus
              minLength="3"
              maxLength="32"
              // pattern="[^\s]+"
              aria-describedby="Enter Name"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => onChange(e)}
              invalid={errorList.name ? true : false}
              required
            />
            <span
              className="form__label--error mt-03-sm"
              role="alert"
              aria-live="assertive"
            >
              <Errors current_key="name" key="name" />
              {/* Required name */}
            </span>
          </div>
          <div className="mb-sm">
            <label htmlFor="email" className="form__label " aria-hidden="true">
              Email
            </label>
            <Input
              className="form__input register__w32"
              type="text"
              name="email"
              minLength="5"
              maxLength="50"
              aria-describedby="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => onChange(e)}
              // onChange={validateEmail}
              invalid={errorList.email ? true : false}
              required
            />
            <span
              className={`mt-03-sm register__err form__label--${
                isValid ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {message}
            </span>
          </div>

          <div className="mb-md">
            <label
              htmlFor="password"
              className="form__label "
              aria-hidden="true"
            >
              Password
            </label>

            <div className="inputs__icons">
              <Input
                className="form__input register__w32"
                type={toggle ? "password" : "text"}
                name="password"
                minLength="6"
                maxLength="16"
                aria-describedby="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => onChange(e)}
                invalid={errorList.password ? true : false}
                required
              />

              <span className="inputs__svg cursor" onClick={clickToggle}>
                {toggle ? (
                  <img src={viewIcon} alt="date icon svg" />
                ) : (
                  <img src={hideIcon} alt="date icon svg" />
                )}
              </span>
            </div>

            <span
              className={`mt-03-sm register__err form__label--${
                messagePassword ? "white" : "error"
                // isValidPassword ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {messagePassword}
            </span>
          </div>

          <Button className="btn btn-svg register__btn" type="submit">
            {/* <Button className="btn btn-svg register__btn" type="submit" disabled={isValid === true ? true : false} > */}
            Next
            <img src={arrRight} />{" "}
          </Button>

          {/* <span className="form__label--error">
            {isValidPhone === false || isValid === false ? "Invalid " : ""}
            {isValid === false ? "Email, " : ""}
            {isValidPhone === false ? "Phone, " : ""}
          </span> */}

          <p className="mt-sm">
            <Link
              to={`/app/admin/login`}
              className="register__link"
              onClick={(e) => loginRedirect(history)}
            >
              Already have an account ? Sign in here
            </Link>
          </p>
        </Form>
      </div>
    </>
  );
};

Register.proTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loginRedirect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  register,
  loginRedirect,
})(Register);
