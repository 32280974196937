import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getActiveUserList, rejectPendingUser } from "actions/pending";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import "reactjs-popup/dist/index.css";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";
import deleteCross from "assets/superAdmin/PendingReques/deleteCross.svg";
import eye from "assets/land/eye.svg";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: "1.5rem",
      textTransform: "capitalize",
      paddingLeft: ".5rem", // override the cell padding for head cells
      paddingRight: ".5rem",
    },
  },
  rows: {
    style: {
      minHeight: "3rem", // override the row height
    },
  },
  cells: {
    style: {
      paddingLeft: ".5rem", // override the cell padding for data cells
      paddingRight: ".5rem",
    },
  },
};

const Index = ({
  rejectPendingUser,
  getActiveUserList,
  pendingList,
  match,
  history,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const name = queryParams.get("name");
  const class_id = queryParams.get("id");

  // useEffect(() => {
  //   getActiveUserList();
  // }, []);

  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    getActiveUserList().then((res) => {
      if (res === undefined) {
        setPending(false);
      } else {
        setPending(false);
      }
    });
  }, [getActiveUserList]);

  const selfApiData = JSON.parse(localStorage.getItem("dataSelfApi"));

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const [formData, setFormData] = useState({
    impairmentAmount: "",
    date_impairment: "",
    transfer: "",
    date_dispose: "",
  });
  const { impairmentAmount, date_impairment, transfer, date_dispose } =
    formData;

  //handleDateChange of datepicker
  const handleDateChange = (date, type) => {
    switch (type) {
      case "date_impairment":
        setFormData({ ...formData, date_impairment: date });
        break;
      case "date_dispose":
        setFormData({ ...formData, date_dispose: date });
        break;
      default:
        break;
    }
  };

  //handle onChange event
  const onChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.split(" ").join(""),
    });
  };

  //onSubmitImpaiDispose
  const onSubmitImpairment = (e) => {
    e.preventDefault();
  };

  //submit onSubmitTransfer form
  const onSubmitTransfer = (e) => {
    e.preventDefault();
  };

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {}, [selectedRows]);

  //cancel form submit
  const onClickHandel = (e) => {
    e.preventDefault();
    // cancelSave(history);
  };
  //////////////////SEARCHING/FILTER///////////////////
  const [searchs, setNewSearch] = useState("");
  const handleSearchChange = (e) => {
    setNewSearch(e.target.value);
  };
  const filtered = !searchs
    ? pendingList
    : pendingList.filter(
        (el) =>
          el.name.toLowerCase().includes(searchs.toLowerCase()) ||
          el.email.toLowerCase().includes(searchs.toLowerCase()) ||
          el.phone.toLowerCase().includes(searchs.toLowerCase()) ||
          el.created_on.toLowerCase().includes(searchs.toLowerCase())
      );
  //////////////////SEARCHING/FILTER///////////////////
  const columns = [
    {
      name: "Sr",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "4rem",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      grow: 2,
    },
    {
      name: "Accountant",
      selector: (row) => row.accountant_count,
      sortable: true,
    },
    {
      name: "Classes",
      selector: (row) => row.class_count,
      sortable: true,
    },
    {
      name: "Assets",
      selector: (row) => row.asset_count,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.action,
      center: true,
      cell: (row) => {
        return (
          <>
            <Link
              to={`/app/admin/active-user-profile?name=${row.name}&id=${row.id}`}
            >
              <img className="cursor" src={eye} alt="edit status" />
            </Link>

            <div className="tooltip">
              <span className="tooltiptext">Inactive</span>
              <Link className=" ml-s-sm">
                <img
                  className="cursor"
                  src={deleteCross}
                  alt="deleteCross status"
                  onClick={() => {
                    delete_user(row);
                  }}
                />
              </Link>
            </div>
          </>
        );
      },
    },
  ];

  const delete_user = (row) => {
    const user_id = row.id;
    const status_reject = 3;
    rejectPendingUser(user_id, status_reject, history);
  };
  return (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings">
        <div className="listing__breadcrum">
          <span className="font-14 ">
            <Link className="op-2" to="/app/admin/dashboard">
              Home/ Dashboard/{" "}
            </Link>
            <span className="c__blue">{name}Active Users</span>
          </span>
        </div>
        <div className="listing">
          <div className="listing__header mb-md">
            <h3 className="heading-3 cap">{name}Active Users</h3>
            <div className="listing__right">
              <Input
                className="form__search"
                type="search"
                name="search"
                placeholder="Search..."
                value={searchs}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <DataTable
            columns={columns}
            data={filtered}
            // data={activeUser}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
    </>
  );
};

Index.proTypes = {
  getActiveUserList: PropTypes.object.isRequired,
  pendingList: PropTypes.array.isRequired,
  rejectPendingUser: PropTypes.object.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  pendingList: state.pending.pendingList,
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getActiveUserList,
  rejectPendingUser,
})(Index);
