import axios from "axios";
import { setAlert } from "./alert";
import {
  LOADING_ON_ADMIN_SUBMIT,
  ADMIN_ERROR,
  REMOVE_ERRORS,
  ADMIN_UPDATED,
  REMOVE_ALERT,
} from "./types.js";
import * as Constants from "constants/index";

// Update Admin
export const updateAdmin =
  (id, name, password, history) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    };
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnSubmit());

    const body = JSON.stringify({
      name,
      password,
    });

    try {
      const res = await axios.put(
        `${Constants.API_BASE_URL}/admin/${id}`,
        body,
        config
      );

      if (res.data.status === 200) {
        dispatch({
          type: ADMIN_UPDATED,
          payload: res.data.data,
        });
        history.push("/app/admin/profile");
        dispatch(setAlert(`${res.data.message}`, "success"));
        window.location.reload();
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: ADMIN_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
        }
      }
    } catch (err) {
      dispatch({ type: ADMIN_ERROR });
      dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    }
  };

// Dispatch Loading
export const loadingOnSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_ADMIN_SUBMIT });
};

// Redirect to PENDING_USER-type screen
export const pendingUserRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};

// Cancel Submit PENDING_USER
export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};
