import axios from "axios";
import { setAlert } from "./alert";
import {
  LOADING_ON_PENDING_USER_SUBMIT,
  PENDING_USER_ERROR,
  REMOVE_ERRORS,
  GET_PENDING_USER_BY_ID,
  REMOVE_ALERT,
} from "./types.js";
import * as Constants from "constants/index";

// get Pending User Profile Data
export const getPendingUserData = (id) => async (dispatch) => {
  dispatch(loadingOnSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };
  dispatch({ type: REMOVE_ERRORS });

  dispatch({
    type: GET_PENDING_USER_BY_ID,
    payload: [],
  });

  try {
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/users/${id}`,
      config
    );

    if (res.data.status === 200) {
      dispatch({
        type: GET_PENDING_USER_BY_ID,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (err) {
    dispatch({ type: PENDING_USER_ERROR });
    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    return undefined;
  }
};
// get userAccessKeyApi
export const userAccessKeyApi = (id) => async (dispatch) => {
  dispatch(loadingOnSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  };
  dispatch({ type: REMOVE_ERRORS });

  try {
    const res = await axios.get(
      `${Constants.API_BASE_URL}/admin/users/${id}/access-key`,
      config
    );

    if (res.data.status === 200) {
      dispatch({
        type: GET_PENDING_USER_BY_ID,
        payload: res.data.data,
      });
      localStorage.setItem("user_access_key", JSON.stringify(res.data.data));
      window.location.replace(
        `${Constants.ACCESS_URL}${res.data.data.access_key}`
      );
    }
  } catch (err) {
    dispatch({ type: PENDING_USER_ERROR });
    dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
  }
};

// Dispatch Loading
export const loadingOnSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_PENDING_USER_SUBMIT });
};

// Redirect to PENDING_USER-type screen
export const pendingUserRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};

// Cancel Submit PENDING_USER
export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};
