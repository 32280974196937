import {
  ADMIN_UPDATED,
  LOADING_ON_ADMIN_SUBMIT,
  ADMIN_ERROR,
} from "../actions/types";

const initalState = {
  currentAdmin: {},
  loading: false,
  listLoading: true,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_ON_ADMIN_SUBMIT:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ADMIN_UPDATED:
      return {
        ...state,
        currentAdmin: payload,
        loading: false,
      };
    default:
      return state;
  }
}
