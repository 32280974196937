import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetPassword, forgotPasswordRedirect } from "actions/adminAuth";
import { Button, Form, Input } from "reactstrap";
import Alert from "views/Notifications/Alert";
import loginMain from "assets/otp/otpImg.svg";

import logo from "assets/login/logo.svg";
import hideIcon from "assets/login/eyeHide.svg";
import viewIcon from "assets/login/eye.svg";
import arrRight from "assets/login/arrRight.svg";

const Index = ({
  resetPassword,
  forgotPasswordRedirect,
  errorList,
  loading,
  history,
}) => {
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    password: "",
    resPassword: "",
  });

  const { email, otp, password, resPassword } = formData;

  useEffect(() => {
    const dataEmail = JSON.parse(localStorage.getItem("resetEmail"));
    if (dataEmail) {
      setFormData({
        email: dataEmail,
      });
    }
  }, []);

  const [toggle, setToggle] = useState(true);
  const [toggleRePassword, setToggleRePassword] = useState(true);
  const [toggleOTP, setToggleOTP] = useState(true);

  const clickToggle = () => {
    setToggle(!toggle);
  };
  const clickToggleOTP = () => {
    setToggleOTP(!toggleOTP);
  };
  const clickToggleRePassword = () => {
    setToggleRePassword(!toggleRePassword);
  };

  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [messagePassword, setMessagePassword] = useState("");
  const [isValidRePassword, setIsValidRePassword] = useState(false);
  const [messageRePassword, setMessageRePassword] = useState("");
  const [isSamePassword, setIsSamePassword] = useState(false);
  const [messageSamePassword, setMessageSamePassword] = useState("");
  const [emailNotGetSms, setEmailNotGetSms] = useState("");

  const [isValidTransfer, setIsValidTransfer] = useState(false);
  const [messageTransfer, setMessageTransfer] = useState("");

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.split(" ").join(""),
    });
    if (e.target.name === "otp") {
      validateNumber(e);
    }
    if (e.target.name === "password") {
      validateRePassword(e);
    }
    if (e.target.name === "resPassword") {
      validateRePassword(e);
    }
  };

  const numberRegex = /^[0-9\b]+$/;

  const validateNumber = (e) => {
    const test = e.target.value;
    if (numberRegex.test(test)) {
      setIsValidTransfer(true);
      setMessageTransfer("It looks good!");
    } else {
      setIsValidTransfer(false);
      setMessageTransfer("Please enter a valid input like string!");
    }
  };

  const validateRePassword = (e) => {
    const a = e.target.value;
    if (a === formData.password) {
      setIsValid(true);
      setIsSamePassword(true);
      setMessageSamePassword("");
    } else if (a === formData.resPassword) {
      setIsValid(true);
      setIsSamePassword(true);
      setMessageSamePassword("");
    } else {
      setIsValid(false);
      setIsSamePassword(false);
      setMessageSamePassword("Oh! Both password are not same");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const email = JSON.parse(localStorage.getItem("resetEmail"));
    if (password !== resPassword) {
      return setMessageSamePassword("Oh! Both password are not matched");
    } else if (email === null || email === "") {
      return (
        setEmailNotGetSms(
          "Sorry! Go back to sreen and reload page then fill again Email."
        ) && setIsValid(false)
      );
      // } else if (otp === "") {
      //   return;
      // } else if (password === "") {
    } else {
      resetPassword(email, otp, password, history);
    }
  };

  return (
    <>
      <div className="login__story">
        <img
          className="otp__img"
          src={loginMain}
          alt="reset email main image"
        />

        <img
          className="login__img--2"
          src={logo}
          alt="login page logo with name"
        />
      </div>
      <div className="login__content">
        <Form className="login__box" onSubmit={(e) => onSubmit(e)}>
          <h2 className="heading-2 mb-sm">Enter New Password</h2>
          <p className="">An OTP has been sent to your email</p>
          {/* <p className="">An  OTP has been sent to your email {changeEmail}</p> */}
          <Link
            to={`/app/admin/enter-registerd-email`}
            className="font-12 c__white border__bottom mb-md"
            onClick={(e) => forgotPasswordRedirect(history)}
          >
            change E-mail
          </Link>
          <Alert />

          <div className="mb-md mt-md-4">
            <div className="otp__resends">
              <label htmlFor="otp" className="form__label" aria-hidden="true">
                Enter OTP here
              </label>
              <p className="otp__resend">
                {/* <p className="otp__resend" onClick={(e) => resend(e)}> */}
                Resend
              </p>
            </div>

            <div className="inputs__icons">
              <Input
                className="form__input"
                type={toggleOTP ? "password" : "text"}
                name="otp"
                autoFocus
                maxLength="4"
                minLength="4"
                aria-describedby="Enter OTP"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => onChange(e)}
                // invalid={errorList.otp ? true : false}
                required
              />

              <span className="inputs__svg cursor" onClick={clickToggleOTP}>
                {toggleOTP ? (
                  <img src={viewIcon} alt="date icon svg" />
                ) : (
                  <img src={hideIcon} alt="date icon svg" />
                )}
              </span>
            </div>
            <span
              className={`mt-03-sm register__err form__label--${
                isValidTransfer ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {messageTransfer}
            </span>

            <div className="mb-sm mt-sm">
              <label
                htmlFor="password"
                className="form__label "
                aria-hidden="true"
              >
                New Password
              </label>

              <div className="inputs__icons">
                <Input
                  className="form__input register__w32"
                  type={toggle ? "password" : "text"}
                  name="password"
                  // autoFocus
                  minLength="6"
                  maxLength="16"
                  aria-describedby="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => onChange(e)}
                  invalid={errorList.password ? true : false}
                  required
                />

                <span className="inputs__svg cursor" onClick={clickToggle}>
                  {toggle ? (
                    <img src={viewIcon} alt="date icon svg" />
                  ) : (
                    <img src={hideIcon} alt="date icon svg" />
                  )}
                </span>
              </div>

              <span
                className={`mt-03-sm register__err form__label--${
                  isValidPassword ? "white" : "error"
                }`}
                role="alert"
                aria-live="assertive"
              >
                {messagePassword}
              </span>
            </div>

            <div className="mb-md">
              <label
                htmlFor="resPassword"
                className="form__label "
                aria-hidden="true"
              >
                Re-enter Password
              </label>

              <div className="inputs__icons">
                <Input
                  className="form__input register__w32"
                  type={toggleRePassword ? "password" : "text"}
                  name="resPassword"
                  // autoFocus
                  minLength="6"
                  maxLength="16"
                  aria-describedby="Re-enter Password"
                  placeholder="Re-enter Password"
                  value={resPassword}
                  onChange={(e) => onChange(e)}
                  // invalid={errorList.resPassword ? true : false}
                  required
                />

                <span
                  className="inputs__svg cursor"
                  onClick={clickToggleRePassword}
                >
                  {toggleRePassword ? (
                    <img src={viewIcon} alt="date icon svg" />
                  ) : (
                    <img src={hideIcon} alt="date icon svg" />
                  )}
                </span>
              </div>

              <span
                className={`mt-03-sm register__err form__label--${
                  isValidRePassword ? "white" : "error"
                }`}
                role="alert"
                aria-live="assertive"
              >
                {messageRePassword}
                {messageSamePassword}
              </span>
            </div>
          </div>
          {/* {loading ? (
            <Button className="btn btn-svg login__btn" disabled>
              Loading....
              <img src={arrRight} />{" "}
            </Button>
          ) : ( */}
          <Button
            className="btn btn-svg login__btn"
            type="submit"
            disabled={isValid === true ? false : true}
          >
            Continue
            <img src={arrRight} />{" "}
          </Button>
          <span
            className={`mt-03-sm register__err form__label--${
              emailNotGetSms !== null || emailNotGetSms !== "" ? "error" : ""
            }`}
            role="alert"
            aria-live="assertive"
          >
            {emailNotGetSms}
          </span>
        </Form>
      </div>
    </>
  );
};

Index.proTypes = {
  resetPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  forgotPasswordRedirect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  resetPassword,
  forgotPasswordRedirect,
})(Index);
