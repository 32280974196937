import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getPendingUserData,
  userAccessKeyApi,
} from "actions/getPendingUserProfileData";
import { updateUser } from "actions/user";
import Spinner from "views/Spinner";
import * as Constants from "constants/index";
import "reactjs-popup/dist/index.css";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";
import eye from "assets/land/eye.svg";

const Index = ({
  updateUser,
  getPendingUserData,
  userAccessKeyApi,
  currentPendingUserProfile,
  match,
  history,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const active_user_id = queryParams.get("id");
  const active_user_name = queryParams.get("name");

  const [formData, setFormData] = useState({
    name: "",
    password: "",
    email: "",
    phone: "",
  });
  const { name, password, email, phone } = formData;

  useEffect(() => {
    getPendingUserData(active_user_id);
  }, [active_user_id]);

  //userAccessKey
  const userAccessKey = (e) => {
    e.preventDefault();
    userAccessKeyApi(active_user_id);
  };

  useEffect(() => {
    if (currentPendingUserProfile) {
      setFormData({
        name: currentPendingUserProfile.name,
        email: currentPendingUserProfile.email,
        phone: currentPendingUserProfile.phone,
      });
    }
  }, []);
  //handle onChange event
  const onChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //submit form
  const onSubmit = (e) => {
    e.preventDefault();
    updateUser(
      active_user_name,
      active_user_id,
      name,
      password,
      phone,
      history
    );
  };
  //cancel form submit
  const onClickHandel = (e) => {
    e.preventDefault();
    // cancelSave(history);
  };

  return currentPendingUserProfile ? (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings">
        <div className="listing__breadcrum">
          <span className="font-14 ">
            <Link className="op-2" to="/app/admin/dashboard">
              Home/ Dashboard/{" "}
            </Link>
            <span className="c__blue">Profile Edit</span>
          </span>
        </div>

        <div className="listing info__container">
          <div className="companyProfile__header">
            <h3 className="heading-3">User Profile Edit</h3>
          </div>

          {/* #################### */}
          <Form onSubmit={(e) => onSubmit(e)}>
            <div className="companyProfile__labels">
              <div className="companyProfile__label">
                <div className="companyProfile__label-data upper">
                  <label
                    htmlFor="name"
                    className="form__label c__blue cap  mb-s-sm"
                    aria-hidden="true"
                  >
                    Name
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    name="name"
                    minLength="3"
                    maxLength="64"
                    aria-describedby="Name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
              </div>
              {/* email */}
              <div className="physical__box">
                <div className="companyProfile__label">
                  <label
                    htmlFor="email"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Email
                  </label>
                  <Input
                    className="form__input not-allowed"
                    type="text"
                    name="email"
                    disabled={true}
                    aria-describedby="Email"
                    placeholder="Email"
                    value={email}
                  />
                </div>
              </div>

              {/* phone */}
              <div className="physical__box">
                <div className="companyProfile__label">
                  <label
                    htmlFor="phone"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    phone
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    name="phone"
                    minLength="3"
                    maxLength="64"
                    aria-describedby="Phone"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => onChange(e)}
                    // required
                  />
                </div>
              </div>
              {/* password */}
              <div className="physical__box">
                <div className="companyProfile__label">
                  <label
                    htmlFor="password"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    password
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    name="password"
                    minLength="3"
                    maxLength="64"
                    aria-describedby="Password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    // required
                  />
                </div>
              </div>
            </div>

            <div className="popup__btns editProfile__btns mt-md">
              <Link
                to={`/app/admin/active-user-profile?name=${active_user_name}&id=${active_user_id}`}
                className="btn btn__border"
              >
                Cancel
              </Link>
              <Button className="btn popup__btn btn__blue cap" type="submit">
                update
              </Button>
            </div>
          </Form>

          {/* #################### */}
        </div>

        {/* <div className="listing">
          <div className="listing__header mb-md">
            <h3 className="heading-3 cap">Acountants</h3>
          </div>
          <DataTable
            columns={columns}
            data={data}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
          />
        </div>
      */}
      </div>
    </>
  ) : (
    <Spinner />
  );
};

Index.proTypes = {
  // updateUser
  updateUser: PropTypes.func.isRequired,
  // get Classes List data
  getPendingUserData: PropTypes.func.isRequired,
  userAccessKeyApi: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentPendingUserProfile: state.pendingProfile.currentPendingUserProfile,
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPendingUserData,
  userAccessKeyApi,
  updateUser,
})(Index);
