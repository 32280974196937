import axios from "axios";
import { setAlert } from "./alert";
import {
  LOADING_ON_USER_SUBMIT,
  USER_ERROR,
  REMOVE_ERRORS,
  USER_UPDATED,
  REMOVE_ALERT,
} from "./types.js";
import * as Constants from "constants/index";

// Update USER
export const updateUser =
  (user_name, user_id, name, password, phone, history) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    };
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnSubmit());

    let body;
    if (password === "" || password === null || password === undefined) {
      body = JSON.stringify({
        name,
        phone,
      });
    } else if (password !== "" || password !== null || password !== undefined) {
      body = JSON.stringify({
        name,
        password,
        phone,
      });
    }

    try {
      const res = await axios.put(
        `${Constants.API_BASE_URL}/admin/users/${user_id}`,
        body,
        config
      );

      if (res.data.status === 200) {
        dispatch({
          type: USER_UPDATED,
          payload: res.data.data,
        });
        history.push(
          `/app/admin/active-user-profile?name=${user_name}&id=${user_id}`
        );
        dispatch(setAlert(`${res.data.message}`, "success"));
        // window.location.reload();
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: USER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));
        }
      }
    } catch (err) {
      dispatch({ type: USER_ERROR });
      dispatch(setAlert(`Errors! ${err.response.data.message}`, "danger"));
    }
  };

// Dispatch Loading
export const loadingOnSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_USER_SUBMIT });
};

// Redirect to PENDING_USER-type screen
export const pendingUserRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};

// Cancel Submit PENDING_USER
export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("#");
};
