import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getUnverifiedList, rejecUnverifiedtUser } from "actions/unverified";
import { Input } from "reactstrap";
import DataTable from "react-data-table-component";
import "reactjs-popup/dist/index.css";
import TopNav from "views/Components/SuperAdmin/Navbars/TopNav/index";
import Sidenav from "views/Components/SuperAdmin/Navbars/Sidebar/index";
import eye from "assets/land/eye.svg";
import activeTic from "assets/land/active.svg";
import deleteCross from "assets/superAdmin/PendingReques/deleteCross.svg";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: "1.5rem",
      textTransform: "capitalize",
      paddingLeft: ".5rem", // override the cell padding for head cells
      paddingRight: ".5rem",
    },
  },
  rows: {
    style: {
      minHeight: "3rem", // override the row height
    },
  },
  cells: {
    style: {
      paddingLeft: ".5rem", // override the cell padding for data cells
      paddingRight: ".5rem",
    },
  },
};

const Index = ({
  getUnverifiedList,
  rejecUnverifiedtUser,
  unverifiedList,
  match,
  history,
}) => {
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "4rem",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      grow: 2,
    },
    {
      name: "Framework",
      selector: (row) => row.reporting_framework,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Date Applied",
      selector: (row) => row.created_on,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.action,
      center: true,
      cell: (row) => {
        return (
          <>
            <img
              className="cursor"
              src={deleteCross}
              alt="deleteCross status"
              onClick={() => {
                delete_user(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const delete_user = (row) => {
    const user_id = row.id;
    rejecUnverifiedtUser(user_id, history);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const name = queryParams.get("name");
  const class_id = queryParams.get("id");

  // useEffect(() => {
  //   getUnverifiedList();
  // }, []);

  const unverifiedData = [];
  if (unverifiedList && unverifiedList.length > 0) {
    unverifiedList.map((el) => {
      const user_status = 1;
      const user_status_inactive = 3;
      if (el.status !== user_status && el.status !== user_status_inactive) {
        unverifiedData.push(el);
      }
    });
  }

  const [pending, setPending] = useState(true);
  useEffect(() => {
    getUnverifiedList().then((res) => {
      if (res === undefined) {
        setPending(false);
      } else {
        setPending(false);
      }
    });
  }, [getUnverifiedList]);

  //////////////////SEARCHING/FILTER///////////////////

  const [searchs, setNewSearch] = useState("");

  const handleSearchChange = (e) => {
    setNewSearch(e.target.value);
  };

  const filtered = !searchs
    ? unverifiedData
    : unverifiedData.filter(
        (el) =>
          el.name.toLowerCase().includes(searchs.toLowerCase()) ||
          el.email.toLowerCase().includes(searchs.toLowerCase()) ||
          el.phone.toLowerCase().includes(searchs.toLowerCase()) ||
          el.created_on.toLowerCase().includes(searchs.toLowerCase())
      );
  //////////////////SEARCHING/FILTER///////////////////

  return (
    <>
      <TopNav />
      <Sidenav />

      <div className="listings">
        <div className="listing__breadcrum">
          <span className="font-14 ">
            <Link className="op-2" to="/app/admin/dashboard">
              Home/ Dashboard/{" "}
            </Link>
            <span className="c__blue">Unverified Users</span>
          </span>
        </div>
        <div className="listing">
          <div className="listing__header mb-md">
            <h3 className="heading-3 cap">{name}Unverified Users</h3>
            <div className="listing__right">
              <Input
                className="form__search"
                type="search"
                name="search"
                placeholder="Search..."
                value={searchs}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <DataTable
            columns={columns}
            data={filtered}
            // data={unverifiedData}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
    </>
  );
};

Index.proTypes = {
  getUnverifiedList: PropTypes.object.isRequired,
  unverifiedList: PropTypes.array.isRequired,
  rejecUnverifiedtUser: PropTypes.object.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  unverifiedList: state.unverified.unverifiedList,
  errorList: state.errors,
  loading: state.auth.loading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getUnverifiedList,
  rejecUnverifiedtUser,
})(Index);
